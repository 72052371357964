import React, { useEffect, useState } from "react";
import logo from './supermat_logo.png'

import format from "number-format.js";
import logoGrande from './logo_grande.svg'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// @mui
import { styled } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { Grid, Container, Typography, radioClasses } from '@mui/material';

import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';

import fondo from './imagen_negra2.jpg'
import request from '../../../request'

// sections
import {
  AppWidgetSummary,
  AppConversionRates,
} from '../../sections/@dashboard/app';

import './spinner.css'
//const APP_BAR_MOBILE = 64;
//const APP_BAR_DESKTOP = 92;
import test from './BYC Prestige Revisado legales 6.9.pdf'

const StyledRoot = styled('div')({
  background: `linear-gradient(rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.90)) ,url(${"./fondo_prestige.jpg"})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '100%,100%',
  width: '100%,100%',
  position: 'relative',
  display: 'flex',
  margin: 0,
  overflow: 'hidden'
});

const Main = styled('div')(({ theme }) => ({
  //background: `linear-gradient(rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.90)),url(${"./fondo_prestige.jpg"})`,
  //background: `url(${"./lineas_brillos.png"})`,
  //zIndex: 1,
  //backgroundRepeat: 'no-repeat',
  //backgroundSize: 'cover',
  flexGrow: 1,
  overflow: 'auto',
  height: '100%',
  width: '100%',
  minHeight: '100%',
  //paddingTop: APP_BAR_MOBILE + 24,
  paddingTop: '28px',
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    //paddingTop: APP_BAR_DESKTOP + 24
    paddingTop: '28px',
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}));

// ----------------------------------------------------------------------
export default function DashboardLayout(props) {

  //Compras mensuales
  const [compras, setCompras] = useState([])

  const [detallePuntos, setDetallePuntos] = useState([])
  const [facturas, setFacturas] = useState([])
  const [totalPuntos, setTotalPuntos] = useState([])
  const [totalesPeriodos, setTotalesPeriodos] = useState([])
  const [totalMesCurso, setTotalMesCurso] = useState([])

  //Datos para las CARDS
  const [millasAcumuladas, setMillasAcumuladas] = useState(0)
  const [millasUltMes, setMillasUltMes] = useState(0)

  //Lo que falta para cada viaje
  const [sanPablo, setSanPablo] = useState(0)
  const [nuevaYork, setNuevaYork] = useState(0)
  const [milan, setMilan] = useState(0)

  const [total1, setTotal1] = useState(0)
  const [total2, setTotal2] = useState(0)
  const [total3, setTotal3] = useState(0)

  const [lastPeriodo, setLastPeriodo] = useState(0)

  const [puntosMilan, setPuntosMilan] = useState(0)
  const [puntosSanPablo, setPuntosSanPablo] = useState(0)
  const [puntosNewYork, setPuntosNewYork] = useState(0)

  const [progresoMilan, setProgresoMilan] = useState(0)
  const [progresoNewYork, setProgresoNewYork] = useState(0)
  const [progresoSanPablo, setProgresoSanPablo] = useState(0)

  const d = new Date();
  var month = (d.getMonth())

  var usrId = props.UsrId
  var mail = props.UsrMail

  {/*
  request.Post(props.props.ipserv + "getpuntoscli/", { usrId : usrId  }, r => {
    if (r.success) {
      Notify('Has Solicitado en Canje de tus Puntos por un Premio! A la brevedad un representante del Equipo se Contactará Contigo! ')

    } else if (r.Error) {
      Notify(r.Error)
    } 
  })
  */}

  useEffect(() => {
    request.Post(props.props.ipserv + "getpuntoscli/", { usrId: usrId }, r => {
      if (r.success) {

        console.log(r.data)

        setMillasAcumuladas(r.data.totalPuntos[0].TotalPuntos)

        setCompras(r.data.totalesperiodo)
        setDetallePuntos(r.data.detallepuntos)
        setFacturas(r.data.facturas)
        setTotalPuntos(r.data.totalPuntos)
        setTotalesPeriodos(r.data.totalesperiodos)
        setTotalMesCurso(r.data.totalmescurso)

        setLastPeriodo(Math.trunc(r.data.totalesperiodo[month].TotalPuntos))

        setPuntosSanPablo(r.data.puntosadestino[0].Puntos)
        setPuntosNewYork(r.data.puntosadestino[1].Puntos)
        setPuntosMilan(r.data.puntosadestino[2].Puntos)

        if (r.data.progreso.length === 0) {
          null
        } else {
          setTotal1(r.data.progreso[2].TOTAL_PUNTOS)
          setTotal2(r.data.progreso[1].TOTAL_PUNTOS)
          setTotal3(r.data.progreso[0].TOTAL_PUNTOS)
        }

        if (r.data.progreso.length === 0) {
          null
        } else {
          setSanPablo(r.data.progreso[0].TE_FALTAN)
          setNuevaYork(r.data.progreso[1].TE_FALTAN)
          setMilan(r.data.progreso[2].TE_FALTAN)
        }

        if (r.data.progreso.length === 0) {
          null
        } else {
          setProgresoMilan(r.data.progreso[0].PROGRESO_PORCENTAJE)
          setProgresoNewYork(r.data.progreso[1].PROGRESO_PORCENTAJE)
          setProgresoSanPablo(r.data.progreso[2].PROGRESO_PORCENTAJE)
        }

      } else if (r.Error) {
        Notify(r.Error)
      }
    })
  }, [props.UsrId])

  //--MODAL--
  const [closeSession, setCloseSession] = useState(false);
  const [show, setShow] = useState(true);
  let history = useHistory();

  const handleClose = () => {
    localStorage.clear();
    setCloseSession(true);
    //Notis.stopNotis()
    history.push('/');
    window.location.reload();
    setShow(false);
  }

  const handleShow = () => setShow(true);

  const handleAceptar = () => {

    request.Post(props.props.ipserv + "saveparameters/", {
      DmnVal: '1',
      DmnId: props.UsrId,
      Tabla: 'fx_usr'
    }, r => {
      if (r.success) {
        console.log(r.success)
      } else if (r.Error) {
        Notify(r.Error)
      }
    })
    setShow(false)
  }

  const theme = createTheme({
    typography: {
      fontFamily: [
        'Montserrat',
      ],
      color: ['white']
        .join(','),
    },
  });

  const title = `¡Hola, ${props.nombre} ${props.apellido} !`

  useEffect(() => {

  }, [props.ipserv])

  return (
    <StyledRoot>
      <Main>
        <>
          <Helmet>
            <title> Supermat | Prestige </title>
          </Helmet>
          {
            (props.terminos === null || props.terminos == 0) ?
              (
                <Modal size="lg" show={show} onHide={handleClose}>
                  <Modal.Header>
                    <Modal.Title>Términos y condiciones</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {/*
                  "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                  totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt
                  explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur
                  magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
                  sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam
                  aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam,
                  nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam
                  nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?" 
                  */}
              
                  <iframe title="PDF in an i-Frame" src={test} height="700px" width="100%" style={{ margin: 'auto' }}></iframe>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button style={{ backgroundColor: '#235197' }} onClick={handleAceptar}>
                      Aceptar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                      No Acepto
                    </Button>
                  </Modal.Footer>
                </Modal>
              )
              : null
            }
          <Container maxWidth="xl" sx={{ width: '100%' }} >

            <div style={{ width: '100%', display: 'flex', justifyContent: 'spaceAround', flexWrap: 'wrap' }}>

              <Typography theme={theme} variant="h3" sx={{ color: 'white', padding: '8px' }} >
                {title}
              </Typography>

              <img src={logoGrande} align="right" alt="logo" style={{ display: '-webkit-box', marginLeft: 'auto', maxWidth: '400px', marginBottom: '5px' }} ></img>

            </div>
            {
              compras.length !== 0 ?
                (
                  <>
                    <Grid style={{ display: 'flex', flexWrap: 'wrap' }} container spacing={1}>

                      {/*----****PUNTOS*****---*/}
                      <Grid item sm={6} md={3} lg={3}>
                        <AppWidgetSummary
                          title="Millas acumuladas"
                          total={millasAcumuladas}
                          icon="" />
                      </Grid>

                      <Grid item sm={6} md={3} lg={3}>
                        <AppWidgetSummary
                          title="Millas el último mes"
                          total={lastPeriodo}
                          icon={'formkit:week'} />
                      </Grid>

                      {/**---TORTAS---**/}
                      <Grid item sm={6} md={3} lg={6}>
                        <AppWidgetSummary title="Promedio semanal"
                          compras={compras}
                          total1={total1}
                          total2={total2}
                          total3={total3}
                          sanPablo={sanPablo}
                          nuevaYork={nuevaYork}
                          milan={milan}
                          progresoMilan={progresoMilan}
                          progresoSanPablo={progresoSanPablo}
                          progresoNewYork={progresoNewYork}
                          total={0}
                          icon={'ic:baseline-view-day'} />
                      </Grid>

                    </Grid>

                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                      {
                        (compras.length === 0) ? null
                          :
                          (<Grid item sm={6} md={3}>
                            <AppConversionRates
                              title="Mis compras"
                              subheader=""

                              chartData={[
                                { label: 'SEPT', value: (typeof compras[7].TotalPuntos === ("null" || "undefined")) ? 0 : Math.trunc(compras[8].TotalPuntos) },
                                { label: 'OCT', value: (typeof compras[1].TotalPuntos === ("null" || "undefined")) ? 0 : Math.trunc(compras[9].TotalPuntos) },
                                { label: 'NOV', value: (typeof compras[2].TotalPuntos === ("null" || "undefined")) ? 0 : Math.trunc(compras[10].TotalPuntos) },
                                { label: 'DIC', value: (typeof compras[3].TotalPuntos === ("null" || "undefined")) ? 0 : Math.trunc(compras[11].TotalPuntos) },
                                { label: '', value: 0 },
                                { label: '', value: 0 },
                              ]}

                              chartData2={[
                                { label: 'ENE', value: 0 },
                                { label: 'FEB', value: 0 },
                                { label: 'MAR', value: 0 },
                                { label: 'ABR', value: 0 },
                                { label: 'MAY', value: 0 },
                                { label: 'JUN', value: 0 },
                              ]}

                              chartData3={[
                                { label: 'JUL', value: 0 },
                                { label: 'AGO', value: 0 },
                                { label: 'SEP', value: 0 },
                                { label: 'OCT', value: 0 },
                                { label: 'NOV', value: 0 },
                                { label: 'DIC', value: 0 },
                              ]}
                            />
                          </Grid>
                          )
                      }

                      <Grid item sm={6} md={3}>
                        <AppWidgetSummary
                          title="1° PREMIO"
                          subtitle="Salone del Mobile"
                          date="16-21, April 2024. Milano"
                          millas={puntosMilan}
                          total={1352831}
                          icon={'formkit:week'}
                          mail={mail}
                          usrId={usrId}
                          props={props}
                          tip={"canje"}
                        />
                      </Grid>

                      <Grid item sm={6} md={3}>
                        <AppWidgetSummary
                          title="2° PREMIO"
                          subtitle="New York Architecture Tours"
                          date="6-10, Mayo 2024. New York"
                          millas={puntosNewYork}
                          total={1723315}
                          icon={'ic:baseline-view-day'}
                          mail={mail}
                          usrId={usrId}
                          props={props}
                          tip={"canje"}
                        />
                      </Grid>

                      <Grid item sm={6} md={3}>
                        <AppWidgetSummary
                          title="3° PREMIO"
                          subtitle="Expo Revestir"
                          date="19-22, Marzo 2024. San Pablo"
                          millas={puntosSanPablo}
                          total={1723315}
                          icon={'ph:user'}
                          mail={mail}
                          usrId={usrId}
                          props={props}
                          tip={"canje"}
                        />
                      </Grid>

                    </Grid>
                  </>
                )
                :
                (
                  <span className="loader"></span>
                )
            }
          </Container>
        </>
      </Main>
    </StyledRoot>
  );
}
