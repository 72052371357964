import React from 'react'

const AcercaDe = () =>{

    return(
        <>
        <h1>Acerca De</h1>
        </>
    )
}

export default AcercaDe