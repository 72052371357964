import React, { useState, useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async';
// theme
import ThemeProvider from '../theme';
// components
import ScrollToTop from '../scroll-to-top';
import { StyledChart } from '../chart';
import DashboardLayout from '../layouts/dashboard';

function DashboardParent(props) {

  const [datausr, setDatausr] = useState(null)

  const checkUserData = () => {
    const data = localStorage.usuario
      ? JSON.parse(localStorage.usuario)[0]
      : null
    setDatausr(data);
  }

  useEffect(() => {
    checkUserData();
  }, [props.ipserv], [datausr])

  return (
    <HelmetProvider >
      <ThemeProvider>
        <ScrollToTop />
        <StyledChart />
        <DashboardLayout
          apellido={datausr && datausr.UsrApel ? datausr.UsrApel : ''}
          nombre={datausr && datausr.UsrNom ? datausr.UsrNom : ''}
          terminos={datausr && datausr.UsrTerms}
          UsrId={datausr && datausr.UsrId}
          UsrMail={datausr && datausr.UsrMail}
          props = {props}
          />
      </ThemeProvider>
    </HelmetProvider >
  )
}

export default DashboardParent;
