import getItemMenu from "./getItemMenu"
import moment from 'moment'

const emp = {
    GEO: 'GEOMACO',
    ELS: 'EL SOL',
    SUP: 'SUPERMAT',
    SAS: 'SUPERMAT SAS',
    ELA: 'SUPERMAT',
    HIP: 'HIPERCAT',
    SIST: 'SISTEMAS',
    DIR: 'DASHBOARD DIRECCION'
}

const lastProps = {
    nom: '',
    fch: null
}

export { lastProps }

export default (nom, fch = moment().format('YYYY-MM-DD')) => {
    const item = getItemMenu()
    const key = item ? item.IMenuEmp : ''
    const menu = item ? item.MenuNom : ''
    const id = item ? item.IMenuId: ''

    lastProps.nom = nom
    lastProps.fch = fch

    return item
        ? emp[key] + '_' + menu + '_' + nom + '_' + (!fch.length ? fch.dsd + '_' + fch.hst : fch) + '_IDMENU: ' + id
        : nom + '_' + (!fch.length ? fch.dsd + '_' + fch.hst : fch)
}