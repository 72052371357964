import request from "../request";

const get = (ipserv, callback, emp) => request.Get(ipserv + 'getsucs/', { emp }, callback)

export default {
    Geo: (ipserv, callback) => get(ipserv, callback, 'geo'),
    Sup: (ipserv, callback) => get(ipserv, callback, 'ssa'),
    Sol: (ipserv, callback) => get(ipserv, callback, 'els'),
    Hip: (ipserv, callback) => get(ipserv, callback, 'hip'),
    Hssa: (ipserv, callback) => get(ipserv, callback, 'Hssa')
}