import React, { useState, useEffect } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import historyService from '../../services/historyService';
import getItemMenu from './getItemMenu';
import Usr from '../../services/user';
import { lastProps } from './nameExcel';
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import download from "downloadjs"
import request from "../../request"
import Notify from "../../components/compartidos/Notify"

export default function DescargarExcel(props) {
    const [loading, setLoading] = useState(false)
    const [time, setTime] = useState(0);

    const porcent = itemExcel => {
        const ft = itemExcel.filasTotales
        const fp = itemExcel.filasTotalesProcesadas

        const p = Math.round((fp / ft) * 100)

        return p ? p + '%' : '0%'
    }

    const card = itemExcel => `<div class="d-flex flex-column text-center justify-content-center align-items-center alerta-excel" style="width: 500px">
                                <div class="fw-medium">${getItemMenu() ? getItemMenu().IMenuNom : 'REPORTE'}</div>
                                <div class="d-flex full-width justify-content-center my-1">
                                    <div class="spinner-grow spinner-grow-sm mx-1"></div>
                                    <div class="spinner-grow spinner-grow-sm mx-1"></div>
                                    <div class="spinner-grow spinner-grow-sm mx-1"></div>
                                    <div class="spinner-grow spinner-grow-sm mx-1"></div>
                                </div>
                                <span class="fw-medium" id="${""}-label">
                                  Procesando... Aguarde Mientras el Archivo se Genera. Esto puede demorar unos minutos...
                                </span>
                                <div class="hidden-not-hover">
                                    Por favor espere, estamos generando su informe.Este mensaje puede desaparecer mienstras se genera el archivo.
                                    <br/>
                                    Si el informe contiene muchas filas puede que demore unos minutos.
                                    <br/>
                                </div>
                            </div>`

    const emp = {
        GEO: 'GEOMACO',
        ELS: 'EL SOL',
        SUP: 'SUPERMAT',
        SAS: 'SUPERMAT SAS',
        ELA: 'SUPERMAT',
        HIP: 'HIPERCAT',
        SIST: 'SISTEMAS',
        DIR: 'DASHBOARD DIRECCION'
    }

    const getFch = () => {
        const fch = lastProps.fch

        if (!fch) return moment().format('DD/MM/YYYY')

        return typeof fch === 'string' ? moment(fch).format('DD/MM/YYYY') : `${moment(fch.dsd).format('DD/MM/YYYY')} - ${moment(fch.hst).format('DD/MM/YYYY')}`
    }

    const getExcel = e => {
        e.preventDefault()

        setLoading(true)
    
        const url = props.ipserv + 'genexcellarge/'
        const tokenExcel = props.tokenExcel
        const nombre = props.nombreExcel || 'Reporte'
        const success = () => setLoading(false)
        const error = () => {
            setLoading(false)
            if (props.error) props.error()
        }
        const columnas = props.columnas || []
        const menu = getItemMenu()
        const menuNom = menu ? menu.IMenuNom + " - " + menu.IMenuEmp : ''
        const user = Usr
        const usuario = `${user.Apellido()}, ${user.Nombre()}`
        const fch = getFch()
        const k = getItemMenu() && getItemMenu().IMenuEmp
        const empresa = emp[k]
        const dataexcel = props.dataExcel
        const tipo = props.tipo

        const paramsCong = {
            Link: historyService.getPathname(),
            MenuId: menu && menu.IMenuId,
            MenuNom: menu && menu.IMenuNom,
            UniNeg: menu && menu.IMenuEmp,
            Usuario: `${user.Id()} - ${user.Apellido()}, ${user.Nombre()}`,
            UsrId: user.Id()
        }
        const paramsExcel = {
            titulo: menuNom?menuNom : props.tituloExcel,
            usuario,
            fch,
            empresa,
            dataexcel,
            nombre , 
            tipo

        }

        esperar(tokenExcel, nombre, url, paramsCong, paramsExcel);
    }

    const esperar = (tokenExcel, nombre, url, paramsCong, paramsExcel) => {
        const { notify, idTimer, hidden } = Notify({ text: card(), classes: 'bg-positive' })
        request.Post(url, { tokenExcel, paramsCong, paramsExcel }, r => {
            download(r, nombre + ".xlsx");
            setLoading(false);
            hidden()
            Notify({ type: 'archivo_success', classes: 'bg-positive' })
        }, 'blob',
            e => { })
    }


    return <Button
        id = 'downloadexcellarge'
        disabled={loading || props.disabled}
        hidden = {props.hidden}
        className={"mx-3 " + props.className}
        style={props.style}
        onClick={getExcel}
        variant={props.variant || 'success'}
    >
        {loading
            ? <Spinner animation="border" size="sm" />
            : props.botonText || <><FontAwesomeIcon icon={faFileExcel} className="mr-2" />Descargar Excel</>
        }
    </Button>
}