import React, {useEffect} from "react";
import { Table } from "react-bootstrap";
import './clases_utiles.css'

export default function TablaScroll (props) {
    useEffect(() => {
        if (!document.getElementById('wrapper1')) {
            return
        }

        var wrapper1 = document.getElementById('wrapper1').parentElement;
        var wrapper2 = document.getElementById('wrapper2').parentElement;

        wrapper1.onscroll = function() {
            wrapper2.scrollLeft = wrapper1.scrollLeft;
        }
        wrapper2.onscroll = function() {
            wrapper1.scrollLeft = wrapper2.scrollLeft;
        }
    }, [props])

    return <>
        <Table
            responsive
            id='wrapper1'
            className='visibility-collapse'
            striped bordered hover
        >
            <thead className="header-tablaa">
                <tr>
                    {props.columnas.map(c => <th key={c}>{c}</th>)}
                </tr>
            </thead>
            <tbody>
                {props.data.map(rows => <tr>
                    {props.columnas.map(c => <td key={c + '_cell'}>{rows[c]}</td>)}
                </tr>)}
            </tbody>
        </Table>

        <Table
            responsive
            id='wrapper2'
            striped bordered hover
        >
            <thead className="header-tablaa">
                <tr>
                    {props.columnas.map(c => <th key={c + c}>{c}</th>)}
                </tr>
            </thead>
            <tbody>
                {props.data.map(rows => <tr>
                    {props.columnas.map(c => <td key={c + c + '_cell'}>{rows[c]}</td>)}
                </tr>)}
            </tbody>
        </Table>
    </>
}