import React, { useEffect, useState } from "react"
import { InputGroup, Form, FormControl } from "react-bootstrap"
import sort from "./sort"
import resetForm from "./resetForm"

export default function FilterQuery(props) {

    const [key, setKey] = useState('')

    const [dataQuery, setDataQuery] = useState("");
    const [col, setCol] = useState([])
    const [values, setValues] = useState({
        value: {
            COD_NUM: '',
            COD_ABRV: '',
            DESCRIPCION: ''
        }
    })

    const cambioInput = e => {
        setDataQuery(e.target.value)
        props.setDataQuery(e.target.value)

    }

    const setInput = e => {
        setKey(e.target.value)
        props.setValues(e.target.value)
    }

    useEffect(() => {
        if (props.col !== undefined) {
            const c = props.col.slice(0).sort((a, b) => sort.alfAsc(a, b, 'label'))

            setCol(c)
        } else {
            setCol([])
        }
        resetForm('#form-filter')
        resetForm('#form-filter-2')
        resetForm('#form-filter-select')
        setKey('')
        setValues({
            value: '',
        })

    }, [JSON.stringify(props.col)])

    return (
        <>
            <InputGroup>
                <InputGroup.Text>Buscar por...</InputGroup.Text>
                <Form.Control
                    id="form-filter-select"
                    as="select"
                    defaultValue=""
                    onChange={setInput}
                >
                    <option value=''>Seleccione una columna</option>
                    {col.map(c => <option key={c.key} value={c.key} >{c.label}</option>)}

                </Form.Control>
                <FormControl
                    id='form-filter'
                    disabled={!key}
                    type="text"
                    placeholder="Buscar"
                    name="value"
                    input="true"
                    value={dataQuery}
                    onChange={cambioInput}

                />
            </InputGroup>
            <div className="text-muted">
                *Puede buscar varios valores, si los separa por coma.
                <br />
            </div>
        </>
    )
}