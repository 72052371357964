import React from 'react'

const PreguntasFrecuentes = () =>{

    return(
        <>
        <h1>PreguntasFrec</h1>
        </>
    )
}

export default PreguntasFrecuentes