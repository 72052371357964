// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';

// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import format from "number-format.js";

// components
import Iconify from '../../../iconify';
import icono from '../../../layouts/dashboard/icono.png'
import Chip from '@mui/material/Chip';
import ReactApexChart from 'react-apexcharts';

import img1 from '../../../layouts/dashboard/milan.png'
import img2 from '../../../layouts/dashboard/new york.png'
import img3 from '../../../layouts/dashboard/brasil.png'

import request from '../../../../request'
import Notify from '../../../compartidos/Notify'

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

const theme = createTheme({
  typography: {
    color: 'white',
    fontFamily: [
      'Montserrat',
    ].join(','),
  },
});



export default function AppWidgetSummary({
  compras,
  title,
  subtitle,
  date,
  millas,
  milan,
  sanPablo,
  nuevaYork,
  progresoMilan,
  progresoSanPablo,
  progresoNewYork,
  total1,
  total2,
  total3,
  total,
  icon,
  mail,
  usrId,
  color = "primary",
  props,
  tip , 
  sx, ...other }) {

  var series1 = [Math.round(sanPablo), Math.round(total1)]
  var series2 = [Math.round(nuevaYork), Math.round(total2)]
  var series3 = [Math.round(milan), Math.round(total3)]

  const sendMail = () => {
    request.Post(props.props.ipserv + "sendmail/", { email: mail , usrId:usrId , tip : tip }, r => {
      if (r.success) {
        Notify('Has Solicitado en Canje de tus Puntos por un Premio! A la brevedad un representante del Equipo se Contactará Contigo! ')
      } else if (r.Error) {
        Notify(r.Error)
      } else {
        Notify('Has Solicitado en Canje de tus Puntos por un Premio! A la brevedad un representante del Equipo se Contactará Contigo! ')
      }
    })
  }


  var chartOptions = {

    chart: {
      type: 'donut',
      legend: {
        show: false
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    stroke: {
      colors: ['none']
    },

    plugins: {
      legend: {
        position: 'Bottom'
      }
    },

    fill: {
      colors: ['#802d09', '#235197'],
    },

    dataLabels: {
      enabled: false,
      formatter: function (value) {
        return `${Math.round(value)}%`
      }
    },

    //etiqueta
    tooltip: {
      enabled: false,
    },

    legend: {
      show: false,
      colors: ['#DD5316', '#235197'],
      labels: {
        useSeriesColors: false
      },
    },

    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              offsetY: 10,
            },
            total: {
              show: true,
              showAlways: true,
              fontSize: '18px',
              fontFamily: 'Montserrat',
              fontWeight: 600,
              label: `${((format("#.##0,00", progresoSanPablo)))}%`,
              //label: `${((Math.trunc(progresoSanPablo)))}`,
              color: 'white',
              formatter: function (w) {
                return '';
              }
            }
          }
        }
      }
    },

  };


  var chartOptions2 = {

    chart: {
      type: 'donut',
      legend: {
        show: false
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    stroke: {
      colors: ['none']
    },

    plugins: {
      legend: {
        position: 'Bottom'
      }
    },

    labels: ['', ''],

    fill: {
      colors: ['#802d09', '#235197'],
      backgroundColor: 'white'
    },

    markers: {
      colors: ['white', 'white']
    },

    dataLabels: {
      style: {
        colors: ['white', 'white']
      },
      enabled: false,
      formatter: function (value) {
        return `${Math.round(value)}%`
      }
    },

    //etiqueta
    tooltip: {
      enabled: false,
    },

    legend: {
      show: false,
      colors: ['#DD5316', '#235197'],
      labels: {
        colors: ['white', 'white'],
        useSeriesColors: false
      },
    },

    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              offsetY: 10,
            },
            total: {
              show: true,
              showAlways: true,
              fontSize: '18px',
              fontFamily: 'Montserrat',
              fontWeight: 600,
              label: `${((format("#.##0,00", progresoNewYork)))}%`,
              color: 'white',
              formatter: function (w) {
                return '';
              }
            }
          }
        }
      }
    },

  };

  //chart3
  var chartOptions3 = {

    chart: {
      type: 'donut',
      legend: {
        show: false
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },

    stroke: {
      colors: ['none']
    },

    plugins: {
      legend: {
        position: 'Bottom'
      }
    },

    labels: ['', ''],

    fill: {
      colors: ['#802d09', '#235197'],
      backgroundColor: 'white'
    },

    markers: {
      colors: ['white', 'white']
    },

    dataLabels: {
      style: {
        colors: ['white', 'white']
      },
      enabled: false,
      formatter: function (value) {
        return `${Math.round(value)}%`
      }
    },

    //etiqueta
    tooltip: {
      enabled: false,
    },

    legend: {
      show: false,
      colors: ['#DD5316', '#235197'],
      labels: {
        colors: ['white', 'white'],
        useSeriesColors: false
      },
    },

    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              offsetY: 10,
            },
            total: {
              show: true,
              showAlways: true,
              fontSize: '18px',
              fontFamily: 'Montserrat',
              fontWeight: 600,
              label: `${((format("#.##0,00", progresoMilan)))}%`,
              color: 'white',
              formatter: function (w) {
                return '';
              }
            }
          }
        }
      }
    },

  };


  return (
    <Card
      sx={{
        textAlign: 'center',
        //color: (theme) => theme.palette[color].darker,
        //bgcolor: (theme) => theme.palette[color].lighter,
        minWidth: (title === "1° PREMIO" || title === "2° PREMIO" || title === "3° PREMIO") ? '330px' : null,
        bgcolor: 'transparent',
        color: 'white',
        backgroundColor: 'rgba(0,0,0,0.60)',
        padding: (title === "1° PREMIO" || title === "2° PREMIO" || title === "3° PREMIO" || title === "Mis compras") ? '0px' : '12px',
        ...sx,
      }}
      {...other}
    >
      {
        title === "Promedio semanal" ?
          (
            <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'center' }}>

              <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', flexDirection: 'column', maxWidth: '200px', marginLeft: '3px', padding: '8px' }}>
                <ReactApexChart type="donut" series={series3} options={chartOptions3} height={170} />
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                  <h5 style={{ fontFamily: 'Montserrat' }}>MILÁN</h5>
                  <h6 style={{ fontFamily: 'Montserrat' }}>TE FALTAN {(format("#.##0,00", Math.round(milan))).slice(0, -3)} MILLAS</h6>
                </div>
              </div>

              <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', flexDirection: 'column', maxWidth: '200px', marginLeft: '3px', padding: '8px' }}>
                <ReactApexChart type="donut" series={series2} options={chartOptions2} height={170} />
                <h5 style={{ fontFamily: 'Montserrat' }}>NUEVA YORK</h5>
                <h6 style={{ fontFamily: 'Montserrat' }}>TE FALTAN {(format("#.##0,00", Math.round(nuevaYork))).slice(0, -3)} MILLAS</h6>
              </div>

              <div align="center" style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', flexDirection: 'column', maxWidth: '200px', padding: '8px' }}>
                <ReactApexChart type="donut" series={series1} options={chartOptions} height={170} />
                <h5 style={{ fontFamily: 'Montserrat' }}>SAN PABLO</h5>
                <h6 style={{ fontFamily: 'Montserrat' }}>TE FALTAN {(format("#.##0,00", Math.round(sanPablo))).slice(0, -3)} MILLAS</h6>
              </div>

              <br></br>
            </div>
          )
          :
          (
            (title === "1° PREMIO" || title === "2° PREMIO" || title === "3° PREMIO") ?
              (
                <div style={{ top: '8px', padding: '15px', display: 'flex', flexDirection: 'column', height: 'auto', justifyContent: 'center', width: '100%' }}>

                  <div style={{ display: 'flex', width: '100%' }}>
                    <h2 style={{ marginTop: '0px', fontFamily: 'Montserrat' }}>{title === "1° PREMIO" ? '1°' : (title === "2° PREMIO" ? '2°' : '3°')}</h2>
                    <h6 style={{ marginTop: '16px', fontFamily: 'Montserrat' }}>PREMIO</h6>
                  </div>

                  <hr style={{ height: '1px', backgroundColor: 'white', padding: '0px', marginBottom: '0px', marginTop: '0px' }}></hr>

                  <h6 align="left" style={{ padding: '0px', marginTop: '0px', fontFamily: 'Montserrat', float: 'left' }}>{subtitle}</h6>
                  <h6 align="left" style={{ padding: '0px', marginTop: '0px', fontFamily: 'Montserrat', float: 'left' }}>{date}</h6>
                  {/*<h3 align="left" style={{ color: 'orange', fontFamily: 'Montserrat' }}>{(format("#.##0,00", millas)).slice(0, -3)} millas </h3>*/}

                  <h3 align="left" style={{ color: 'orange', fontFamily: 'Montserrat' }}>{title === "1° PREMIO" ? '5000' : (title === "2° PREMIO" ? '3500' : '2000')} millas </h3>
                  {/*contacto@supermatprestige.com.ar*/}
                  <Chip onClick={sendMail} style={{ float: 'left', marginBottom: '16px', marginLeft: '7px', maxWidth: '150px', fontFamily: 'Montserrat' }} color="primary" label="CANJEAR MILLAS" />

                </div>
              )
              :
              (
                <>
                  <StyledIcon
                    sx={{
                      //color: (theme) => theme.palette[color].dark,
                      backgroundImage: (theme) =>
                        `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
                          theme.palette[color].dark,
                          0.24
                        )} 100%)`,
                    }}
                  >
                    {
                      (title === "Millas acumuladas") ?
                        <>
                          <img alt="icono" style={{ maxWidth: '40px' }} src={icono}></img>
                        </>
                        :
                        (
                          <>
                            <Iconify icon={icon} width={24} height={24} >
                            </Iconify>
                          </>
                        )
                    }
                  </StyledIcon>

                  {/*<Typography theme={theme} variant="h3">{(format("#.##0,00", total)).slice(0, -3)}</Typography>*/}

                  <Typography theme={theme} variant="h3">{Math.trunc(total)}</Typography>

                  <Typography theme={theme} variant="h4">
                    {title}
                  </Typography>

                  {
                    (title === "Millas acumuladas") ?
                      <>
                        <br></br>
                        <br></br>
                      </>
                      :
                      (
                        null
                      )
                  }

                </>
              )
          )
      }
    </Card>
  );
}


