import base from './base'

export default {
    /**
     * 
     * @param {string} url                      Url
     * @param {object} body                     Objeto con los parametros enviados
     * @param {function} callback               Callback que se ejecuta con la respuesta de la llamada
     * @param {string=} [responseType="json"]   Tipo de respuesta, json por defecto
     * @param {function} error                  Callback que se ejecuta si sucede un error (opcional)
     * @param {boolean} [file=false]            Indica si es un archivo lo que se esta enviando, false por defecto
     */
    Get: (url, body, callback, responseType = 'json', error = null, file = false) => {
        base('GET', url, body, callback, responseType, error, file)
    },  
    /**
     * 
     * @param {string} url                      Url
     * @param {object} body                     Objeto con los parametros enviados
     * @param {function} callback               Callback que se ejecuta con la respuesta de la llamada
     * @param {string=} [responseType="json"]   Tipo de respuesta, json por defecto
     * @param {function} error                  Callback que se ejecuta si sucede un error (opcional)
     * @param {boolean} [file=false]            Indica si es un archivo lo que se esta enviando, false por defecto
     */
    Post: (url, body, callback, responseType = 'json', error = null, file = false) => {
        base('POST', url, body, callback, responseType, error, file)
    }
}