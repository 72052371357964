import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';

// @mui
import { Box, Card } from '@mui/material';

// utils
import { fNumber } from '../../../utils/formatNumber';

// components
import { useChart } from '../../../chart';

// ----------------------------------------------------------------------

AppConversionRates.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
};

export default function AppConversionRates({ title, subheader, chartData, chartData2, chartData3, ...other }) {

  const chartLabels = chartData.map((i) => i.label);

  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = useChart({

    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '50%',
        borderRadius: 7,
        padding: '0px',
        marginTop: '0px',
      },
      
    },
    grid: {
      padding: {
        bottom:0,
        left:0
      },
      yaxis: {
        lines: {
            show: false
        }
      },
    },

    dataLabels: {
      enabled: true,
      style: {
        fontSize: '10px',
        colors: ['#fff'],
      },
      formatter: function (value) {
        return (value === 0) ? '' : `${value} M`
      }
    },

    tooltip: {
      enabled: false,
    },

    yaxis: {
      labels: {
        style: {
          colors: ['white', 'white'],
          fontSize: '12px',
          fontFamily: 'Montserrat',
          fontWeight: 400,
        },
      },
      dataLabels: {
        style: {
          colors: ['white']
        }
      },
      markers: {
        colors: ['white']
      }
    },

    xaxis: {
      categories: chartLabels,
      labels: {
        style: {
          colors: ['none', 'none'],
          fontSize: '12px',
          fontFamily: 'Montserrat',
          fontWeight: 400,
        },
      },
      dataLabels: {
        style: {
          colors: ['white']
        }
      },
      markers: {
        colors: ['white']
      }
    },

    markers: {
      colors: ['white']
    },

  });

  const chartLabels2 = chartData2.map((i) => i.label);

  const chartSeries2 = chartData2.map((i) => i.value);

  const chartOptions2 = useChart({

    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '50%',
        borderRadius: 7,
        padding: '0px',
        marginTop: '0px'
      },
      dataLabels: {
        position: 'bottom',
      },
    },

    dataLabels: {
      enabled: true,
      offsetX: -6,
      position: 'bottom',
      style: {
        fontSize: '10px',
        colors: ['#fff'],
        marginLeft: '7px'
      },
      formatter: function (value) {
        return (value === 0) ? '' : `${value} M`
      }
    },

    //Borde
    stroke: {
      show: false,
      width: 1,
      colors: ['#fff']
    },
    grid: {
      padding: {
        bottom:0,
        left:0
      },
      yaxis: {
        lines: {
            show: false
        }
      },
    },
    yaxis: {
      labels: {
        lines: {
          show: false
        },
        style: {
          colors: ['white', 'white'],
          fontSize: '12px',
          fontFamily: 'Montserrat',
          fontWeight: 400,
        },
      },
      dataLabels: {
        style: {
          colors: ['white']
        }
      },
      markers: {
        colors: ['white']
      }
    },

    xaxis: {
      categories: chartLabels2,
      labels: {
        style: {
          colors: ['none', 'none'],
          fontSize: '12px',
          fontFamily: 'Montserrat',
          fontWeight: 400,
        },
      },
      dataLabels: {
        style: {
          colors: ['white']
        }
      },
      markers: {
        colors: ['white']
      }
    },

    markers: {
      colors: ['white']
    },

    tooltip: {
      enabled: false,
    },

  });

  const chartLabels3 = chartData3.map((i) => i.label);

  const chartSeries3 = chartData3.map((i) => i.value);

  const chartOptions3 = useChart({

    chart: {
      type: "bar",
      sparkline: {
        enabled: false
      }
    },

    plotOptions: {
      bar: {
        parentheightoffset: 0,
        horizontal: true,
        barHeight: '50%',
        borderRadius: 7,
        padding:'0px',
        sparkline: {
          enabled: true
        }
      },
      dataLabels: {
        position: 'bottom',
      },
    },
    title: {
      text: '',
      margin: 0,
      offsetX: 0,
      offsetY: 0,
      floating: false,
    },
    grid: {
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left:1
      },
      //remueve las lineas que separan las barras
      yaxis: {
        lines: {
            show: false
        }
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      position: 'bottom',
      style: {
        fontSize: '10px',
        colors: ['#fff'],
        marginLeft: '7px'
      },
      formatter: function (value) {
        return (value === 0) ? '' : `${value} M`
      }
    },

    //Borde
    stroke: {
      show: false,
      width: 1,
      colors: ['#fff']
    },

    yaxis: {
      show: true,
      labels: {
        style: {
          colors: ['white', 'white'],
          fontSize: '12px',
          fontFamily: 'Montserrat',
          fontWeight: 300,
        },
        labels: {
          offsetX: -10
        }
      },
      dataLabels: {
        style: {
          colors: ['white']
        }
      },
      markers: {
        colors: ['white']
      }
    },

    xaxis: {
      categories: chartLabels3,
      labels: {
        style: {
          colors: ['none', 'none'],
          fontSize: '12px',
          fontFamily: 'Montserrat',
          fontWeight: 400,
        },
      },
      dataLabels: {
        style: {
          colors: ['white']
        }
      },
      markers: {
        colors: ['white']
      }
    },

    markers: {
      colors: ['white']
    },

    tooltip: {
      enabled: false,
    },

  });

  return (
    <Card sx={{
      padding: '2px',
      backgroundColor: 'rgba(0,0,0,0.25)',
    }}
      {...other}
    >
      <h5 style={{ fontFamily: 'Montserrat', marginTop: '20px', color: 'white', marginLeft: '35px' }}>Mis compras</h5>

      <Box sx={{ mx: 2, display: 'flex', flexWrap: 'nowrap', justifyContent: 'center', padding: '0px' }} dir="ltr">

        <div>

          <h6 style={{ color: 'white', fontFamily: 'Montserrat', marginLeft:'6px', marginTop:'5px' }}>2023</h6>

          <ReactApexChart  sx={{ color: 'white'}} type="bar" series={[{ data: chartSeries }]} options={chartOptions} height={240} width={150} />

        </div>

        <div>

            <h6 style={{ color: 'white', fontFamily: 'Montserrat', marginLeft:'6px', marginTop:'5px' }}>2024</h6>

            <ReactApexChart sx={{ color: 'white' }} type="bar" series={[{ data: chartSeries2 }]} options={chartOptions2} height={240} width={150} />

            <ReactApexChart sx={{ color: 'white' }} type="bar" series={[{ data: chartSeries3 }]} options={chartOptions3} height={240} width={150} /> 

        </div>

      </Box>
    </Card>
  );
}
