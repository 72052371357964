import history from "../history";

const getQuery = () => {
    const s = history.location.search.substring(1)
    let q = {}

    s && s.split('&').forEach(parQ => {
        const par = parQ.split('=')
        const key = par[0]
        const value = par[1]

        q[key] = value
    })

    return q
}

const getQueryVariable = key => getQuery()[key]

const getPathname = () => history.location.pathname

const listenQuery = (set, key = '', pathname = '') => {
    const unlisten = history.listen(() => {
        if (pathname && getPathname() !== pathname) {
            unlisten()
        } else {
            const data = key
                ? getQueryVariable(key)
                : getQuery()

            set(data)
        }
    })

    return unlisten
}

const listenPathname = (set, pathname= '') => {
    const unlisten = history.listen(() => {
        if (pathname && getPathname() !== pathname) {
            unlisten()
        } else {
            const data = getPathname()

            set(data)
        }
    })

    return unlisten
}

const historyService = {
    ...history,
    getQuery,
    getQueryVariable,
    getPathname,
    listenQuery,
    listenPathname
}

// window.historyService = historyService

export default historyService