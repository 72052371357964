import React, { useEffect } from "react";
import moment from "moment";
import { InputGroup, FormControl } from "react-bootstrap";
import setValueForm from "./setValueForm";

export default function ComboFechas(props) {
    const FchDesde = e => props.setFechDesde(e.target.value)
    const FchHasta = e => props.setFechHasta(e.target.value)

    const getIdFormDesde = () => props.idFormDesde ? '#' + props.idFormDesde : '#id-combo-fechas-desde'
    const getIdFormHasta = () => props.idFormHasta ? '#' + props.idFormHasta : '#id-combo-fechas-hasta'

    const formatFch = fch => moment(fch).format('yyyy-MM-DD')

    const setFechDesde = fch => {
        const idFormDesde = getIdFormDesde()
        setValueForm(idFormDesde, fch)
        props.setFechDesde(fch)
    }

    const setFechHasta = fch => {
        const idFormHasta = getIdFormHasta()
        setValueForm(idFormHasta, fch)
        props.setFechHasta(fch)
    }

    const setComboMes = mes => {
        const date = new Date()
        const primerDia = new Date(date.getFullYear(), date.getMonth() + mes, 1)
        const ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1 + mes, 0)
        const priFormat = formatFch(primerDia)
        const ultFormat = formatFch(ultimoDia)

        !props.soloFchHst && setFechDesde(priFormat)
        !props.soloFchDsd && setFechHasta(ultFormat)
    }

    useEffect(() => {
        if (!props.soloFchHst) setFechDesde(props.valueFchDsd || '')
    }, [props.valueFchDsd])

    useEffect(() => {
        if (!props.soloFchDsd) setFechHasta(props.valueFchHst || '')
    }, [props.valueFchHst])

    useEffect(() => {
        if (props.defaultFchDsd) setFechDesde(props.defaultFchDsd)
        if (props.defaultFchHst) setFechHasta(props.defaultFchHst)

        if (props.defaultComboMes || props.defaultComboMes === 0) setComboMes(props.defaultComboMes)
    }, [])

    return <>
        {!props.soloFchHst &&
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">Fecha Desde</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    id={props.idFormDesde || 'id-combo-fechas-desde'}
                    disabled={props.disabledDsd || props.disabledCombo}
                    type="date"
                    min='1900-01-01' 
                    max='9999-12-31'
                    name="fchdsd"
                    className="mr-sm-2"
                    onChange={FchDesde}
                />
            </InputGroup>
        }

        {!props.soloFchDsd &&
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon2">Fecha Hasta</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    id={props.idFormHasta || 'id-combo-fechas-hasta'}
                    disabled={props.disabledHst || props.disabledCombo}
                    type="date"
                    min='1900-01-01' 
                    max='9999-12-31'
                    name="fchhst"
                    className="mr-sm-2"
                    onChange={FchHasta}
                />
            </InputGroup>
        }
    </>
}