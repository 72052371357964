import React from 'react'
import { Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas, faSearch } from '@fortawesome/free-solid-svg-icons'

export default function BotonConsultar (props) {
    const search = e => {
        e.preventDefault()
        props.search()
    }

    return <Button
        className={"mx-sm-2 " + props.className}
        style={props.style}
        onClick={search}
        disabled={props.disabled}
        variant={props.variant}
    >
        {props.loading
            ? <Spinner animation="border" size="sm" />
            : ( props.botonText || <><FontAwesomeIcon icon={faSearch} className="mr-2" />Consultar</> )
        }
    </Button>
}