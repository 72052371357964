import React, { Fragment, useEffect, useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import request from '../../../request';
import MenuPrincipal from "../../../components/webcomponents/principalmenu/PrincipalMenu";
import DinamicView from "../../../components/sistema/Core/DinamicView"
import TemplateVistaSimple from "../../compartidos/TemplateVistaSimple";
import getSucs from "../../../services/getSucs";

/**
 * Route de Query Automatico
 * 
 * @param   {number}        QueId               AutQId de tabla cr_autq
 * @param   {string}        ipserv              Ip activa
 * @param   {string}        Title               Titulo de la vista
 * @param   {Object}        params              Parametros para la vista. Ver TemplateVistaSimple
 * 
 * To do
 *      - Nombre de excel
 */
export default function DinamicRoute(props) {
    const [sucs, setSucs] = useState(null)

    useEffect(() => {
        if (props.ipserv.length > 0) {
            if (props.params.sucs && typeof props.params.sucs === 'string') {
                const emp = props.params.sucs
                const method = emp[0].toUpperCase() + emp.slice(1).toLowerCase()

                getSucs[method](props.ipserv, r => {
                    let sucs_aux = r.map(s => {
                        return {
                            label: s.SUC,
                            value: s.ID
                        }
                    })

                    if (props.params.sucsAll) sucs_aux.unshift({ label: 'TODO', value: 'all' })
    
                    setSucs(sucs_aux)
                })
            } else if (props.params.sucs && Array.isArray(props.params.sucs)) {
                setSucs(props.params.sucs)
            }
        }
    }, [props.ipserv])
    
    return <TemplateVistaSimple
        ipserv={props.ipserv}
        QueId={props.QueId}
        titulo={props.Title}
        route="getcoreaut"
        fch={props.params.fch === undefined || props.params.fch}
        nameExcel="Reporte"
        columnasFilter={props.params.columnas}
        colQuery={props.params.col}
        filtros={props.params.filtros}
        sucs={sucs}
    />
}