import download from "downloadjs"
import getItemMenu from "../components/compartidos/getItemMenu"
import Notify from "../components/compartidos/Notify"
import request from "../request"
import getTokenRandom from "./getTokenRandom"

let queue = []
let listen = () => {}

const getItemQueue = token => {
    const i = queue.findIndex(q => q.tokenQueue === token)

    if (i >= 0) return queue[i]

    return null
}

const porcent = itemExcel => {
    const ft = itemExcel.filasTotales
    const fp = itemExcel.filasTotalesProcesadas

    const p = Math.round((fp / ft) * 100)

    return p ? p + '%' : '0%'
}

const card = itemExcel =>   `<div class="d-flex flex-column text-center justify-content-center align-items-center alerta-excel" style="width: 500px">
                                <div class="fw-medium">${getItemMenu() ? getItemMenu().IMenuNom : 'REPORTE'}</div>
                                <div class="fw-medium">Hoja <span id="${itemExcel.token}-hoja">${itemExcel.hoja}</span>/${itemExcel.hojas}</div>
                                <div class="fw-medium">Filas <span id="${itemExcel.token}-filas">${itemExcel.fila}/${itemExcel.filas}</span></div>
                                <div class="d-flex full-width justify-content-center my-1">
                                    <div class="spinner-grow spinner-grow-sm mx-1"></div>
                                    <div class="spinner-grow spinner-grow-sm mx-1"></div>
                                    <div class="spinner-grow spinner-grow-sm mx-1"></div>
                                    <div class="spinner-grow spinner-grow-sm mx-1"></div>
                                </div>
                                <span class="fw-medium" id="${itemExcel.token}-label">
                                    ${itemExcel.hoja === itemExcel.hojas && itemExcel.fila === itemExcel.filas
                                        ? 'Procesando...'
                                        : 'Escribiendo...'
                                    }
                                </span>
                                <span class="fw-medium" id="${itemExcel.token}-porcentaje">
                                    ${porcent(itemExcel)}
                                </span>
                                <div class="hidden-not-hover">
                                    Por favor espere, estamos generando su informe.
                                    <br/>
                                    Puede seguir navegando por el sitio mientras espera.
                                    <br/>
                                    Si el informe contiene muchas filas puede que demore unos minutos.
                                    <br/>
                                </div>
                            </div>`

const esperar = (tokenExcel, nombre, url, paramsCong, notify, hidden, success, error, tokenQueue, comenzar) => {
    const item = getItemQueue(tokenQueue)

    const consultar = () => request.Post(url, { tokenExcel, paramsCong, queue: queue.map(q => q.tokenExcel) }, r => {
        download(r, nombre + ".xlsx");
        hidden()
        Notify({ type: 'archivo_success', classes: 'bg-positive' })

        item.completed = true
        item.pending = false
        item.processing = false

        const i = queue.findIndex(q => q.tokenQueue === item.tokenQueue)
        queue.splice(i, 1)
        queue.push(item)

        listen(queue)

        if (success) success()

        const next = queue.filter(q => q.pending)[0]
        if (next) comenzar(next)
    }, 'blob',
    e => {
        if (e.pending) {
            console.log(document.getElementById(tokenExcel + '-hoja'), document.getElementById(tokenExcel + '-filas'), document.getElementById(tokenExcel + '-label'))
            document.getElementById(tokenExcel + '-hoja') && (document.getElementById(tokenExcel + '-hoja').innerHTML = e.hoja)
            document.getElementById(tokenExcel + '-filas') && (document.getElementById(tokenExcel + '-filas').innerHTML = `${e.fila}/${e.filas}`)
            document.getElementById(tokenExcel + '-label') && (document.getElementById(tokenExcel + '-label').innerHTML = e.hoja === e.hojas && e.fila === e.filas
                ? 'Procesando...'
                : 'Escribiendo...'
            )
            document.getElementById(tokenExcel + '-porcentaje') && (document.getElementById(tokenExcel + '-porcentaje').innerHTML = porcent(e))
            
            item.filasTotalesProcesadas = e.filasTotalesProcesadas

            listen(queue)

            esperar(tokenExcel, nombre, url, paramsCong, notify, hidden, success, error, tokenQueue, comenzar)
        } else {
            item.fail = true
            item.pending = false
            item.processing = false
            listen(queue)

            if (error) error()

            const next = queue.filter(q => q.pending)[0]
            if (next) comenzar(next)
        }
    })

    setTimeout(consultar, 5000) // espera 5 segundos
}

const comenzar = props => {
    const { url, tokenExcel, paramsCong, paramsExcel, columnas, success, error, tokenQueue, nombre } = props

    const item = getItemQueue(tokenQueue)
    item.processing = true

    const i = queue.findIndex(q => q.tokenQueue === item.tokenQueue)
    queue.splice(i, 1)
    queue.unshift(item)
    
    listen(queue)

    request.Post(url, { tokenExcel, paramsExcel, columnas, paramsCong }, r => {
        const { notify, idTimer, hidden } = Notify({ text: card(r), classes: 'bg-positive' })

        window.clearTimeout(idTimer)

        item.filasTotales = r.filasTotales
        item.filasTotalesProcesadas = r.filasTotalesProcesadas
        listen(queue)

        esperar(tokenExcel, nombre, url, paramsCong, notify, hidden, success, error, tokenQueue, comenzar)
    }, 'json', e => {
        item.fail = true
        item.pending = false
        item.processing = false
        listen(queue)

        if (error) error()

        const next = queue.filter(q => q.pending)[0]
        if (next) comenzar(next)
    })
}

const addItem = props => {
    const item = {
        ...props,
        pending: true,
        processing: false,
        tokenQueue: getTokenRandom()
    }

    const i = queue.findIndex(q => q.completed || q.fail)

    i < 0
        ? queue.push(item)
        : queue.splice(i, 0, item)

    listen(queue)

    if (queue.filter(q => q.pending).length === 1) comenzar(item)
}

const deleteItem = token => {
    const i = queue.findIndex(q => q.tokenQueue === token)

    if (i >= 0) {
        const item = queue.splice(i, 1)

        listen(queue)

        return item
    }

    return null
}

const deleteAll = () => {
    queue.splice(0)

    listen(queue)
}

const activateItem = token => {
    const item = getItemQueue(token)

    item.completed = false
    item.pending = true
    item.fail = false

    
    const i = queue.findIndex(q => q.tokenQueue === token)
    queue.splice(i, 1)

    const j = queue.findIndex(q => q.completed || q.fail)

    j < 0
        ? queue.push(item)
        : queue.splice(j, 0, item)

    listen(queue)

    if (queue.filter(q => q.pending).length === 1) comenzar(item)
}

const elevar = token => {
    const i = queue.findIndex(q => q.tokenQueue === token)
    const item = queue.splice(i, 1)[0]

    queue.unshift(item)

    const j = queue.findIndex(q => q.processing)
    const itemProcessing = queue.splice(j, 1)[0]

    queue.unshift(itemProcessing)

    listen(queue)
}

const setListen = fn => listen = fn

const cant = filter => queue.filter(q => q[filter]).length

export default {
    addItem,
    deleteItem,
    deleteAll,
    getItemQueue,
    activateItem,
    elevar,
    setListen,
    cant,
    porcent,
    get queue () { return queue }
}