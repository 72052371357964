import React, { useState, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Router, Switch, Route } from "react-router-dom";
import history from "./history";

//MenuPrincipal
import MenuPrincipal from "./components/webcomponents/principalmenu/PrincipalMenu";
import PrimaryView from "./components/webcomponents/primaryview/primaryview";

//Login
import Login from "./components/user/login/Login";

//Estructural Functions
import EchoSrv from "./components/user/echosrv/EchoSrv";

//Request
import request from "./request";

import Notify from "./components/compartidos/Notify";
import DinamicRoute from "./components/sistema/Core/DinamicRoute";

//Parametros Editables 
import ParametrosEditables from "./components/ParametrosEditables/ParametrosEditables"

//DashBoard 
import DashboardParent from './components/dashboard/DashboardParent'

import PreguntasFrecuentes from "./components/layouts/preguntasFrec/PreguntasFrec";

import AcercaDe from "./components/layouts/acercaDe/AcercaDe";

function App() {

  const [ipserv, setipserv] = useState([]);

  const [datasat, setDataSat] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true)

  const getData = () => request.Post(ipserv + 'getAutQ/', {}, r => {
    setDataSat(r.rows)
    setLoadingPage(false)
  }, 'json', e => {
    console.log(e)
    Notify({ type: 'fail' })
    setLoadingPage(false)
  })

  useEffect(() => {
    if (ipserv.length > 0) getData() // setTimeout(getData, 10000)
  }, [ipserv])

  return (
    <div id="root">
      <Router history={history}>
        <MenuPrincipal ipserv={ipserv} setipserv={setipserv} />

        <Switch>

          <Route strict path="/DashboardParent">
            <DashboardParent ipserv={ipserv}   />
          </Route>

          <Route strict path="/PreguntasFrecuentes">
            <PreguntasFrecuentes ipserv={ipserv}   />
          </Route>

          <Route strict path="/AcercaDe">
            <AcercaDe ipserv={ipserv}   />
          </Route>

          <Route strict path="/ParametrosEditables">
            <ParametrosEditables ipserv={ipserv} />
          </Route>

          <Route strict path="/EchoSrv">
            <EchoSrv ipserv={ipserv} />
          </Route>

          <Route strict path="/Login">
            <Login ipserv={ipserv} loadingPage={loadingPage} />
          </Route>

          {datasat && datasat.length && datasat.map(rows =>
            <Route key={rows.AutQComp} strict path={"/" + rows.AutQComp}  >
              <DinamicRoute key={rows.AutQId} params={rows.AutQParams ? JSON.parse(rows.AutQParams) : {}} Title={rows.AutQTitle} QueId={rows.AutQId} ipserv={ipserv} />
            </Route>
          )}

          <Route strict path="/">
            <Login ipserv={ipserv} loadingPage={loadingPage} />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
