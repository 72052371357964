import React, { useEffect, useState } from "react"
import { Pagination, OverlayTrigger, Tooltip } from "react-bootstrap"

import "./clases_utiles.css"

export default function Paginacion (props) {
    const [page, setPage] = useState(1)
    const [maxPages, setMaxPages] = useState(1)
    const [filas, setFilas] = useState(0)

    const onChangePage = accion => {
        const p = accion === 'prev'
            ? page - 1
            : page + 1

        const offset = (p - 1) * props.rows
        const limit = offset + props.rows

        const data = props.data

        const list = data.slice(offset, limit)

        setPage(p)
        setFilas(list.length)

        props.onChangePage(list, p)
    }

    useEffect(() => {
        if (props.page) {
            setPage(props.page)
        }
    }, [props.page])


    useEffect(() => {
        if (props.maxPages) {
            setMaxPages(props.maxPages)
        }
    }, [props.maxPages])

    useEffect(() => {
        const numRows = props.data.length
        const pages = Math.ceil(numRows / props.rows)

        const offset = (page - 1) * props.rows
        const limit = offset + props.rows

        const data = props.data

        const list = data.slice(offset, limit)

        setFilas(list.length)
        setMaxPages(pages)
        setPage(1)

        props.onChangePage(list, 1)
    }, [props.data])

    return <Pagination className="" size="sm">
            <Pagination.Prev
                disabled={page === 1}
                onClick={() => onChangePage('prev')}
            />

            <Pagination.Item
                className="fw-bold relative-position paginacion-label"
            >
                <div className="absolute tooltip-paginacion text-center">
                    Viendo {filas} filas de {props.data.length}
                </div>
                {page} / {maxPages}
            </Pagination.Item>

            <Pagination.Next
                disabled={page === maxPages}
                onClick={() => onChangePage('next')}
            />
        </Pagination>
}