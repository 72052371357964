import { useState } from "react";

export default function useData (props) {
    const init = props || {}

    const [data, setData] = useState(init)

    const setDataEvent = e => setData({
        ...data,
        [e.target.name]: e.target.value
    })

    const set = e => {
        e.target && e.target.name
            ? setDataEvent(e)
            : setData({ ...data, ...e })
    }

    const resetData = (obj = {}) => setData({ ...init, ...obj })

    return [data, set, resetData, setData]
}