import React, { useEffect, useState } from "react"
import { InputGroup, Form, FormControl } from "react-bootstrap"
import moment from 'moment'
import resetForm from "./resetForm"
import sort from "./sort"
import keyEnterConfirm from "./keyEnterConfirm"

export default function Filter (props) {
    const [key, setKey] = useState('')
    const [tipo, setTipo] = useState('')
    const [values, setValues] = useState({
        value: '',
        valueEnd: ''
    })
    const [columnas, setColumnas] = useState([])

    const select = e => {
        setValues({
            value: '',
            valueEnd: ''
        })
        resetForm('#form-filter')
        resetForm('#form-filter-2')

        if (!e.target.value) {
            setKey('')
            setTipo('')
            return
        }

        const key = e.target.value
        const tipo = columnas.find(c => c.key === key).tipo

        setKey(key)
        setTipo(tipo)
    }

    const onChangeValue = e => {
        const name = e.target.name
        const value = e.target.value

        setValues({
            ...values,
            [name]: value
        })
    }

    const tipoFiltro = () => {
        switch (tipo) {
            case 'texto':
                return <FormControl
                    id='form-filter'
                    disabled={!key}
                    type="text"
                    placeholder="Buscar"
                    name="value"
                    onChange={onChangeValue}
                    onKeyUp={e => keyEnterConfirm(e, accion)}
                />

            case 'fecha':
                return <FormControl
                    id='form-filter'
                    type="date"
                    name="value"
                    onChange={onChangeValue}
                    onKeyUp={e => keyEnterConfirm(e, accion)}
                />

            case 'valor':
                return <>
                    <FormControl
                        id='form-filter'
                        type="number"
                        name="value"
                        placeholder="Mayor o igual que..."
                        onChange={onChangeValue}
                        onKeyUp={e => keyEnterConfirm(e, accion)}
                    />
                    <FormControl
                        id='form-filter-2'
                        type="number"
                        name="valueEnd"
                        placeholder="Menor o igual que..."
                        onChange={onChangeValue}
                        onKeyUp={e => keyEnterConfirm(e, accion)}
                    />
                </>
        
            default:
                return <FormControl
                    id='form-filter'
                    disabled={!key}
                    type="text"
                    placeholder="Buscar"
                    name="value"
                    onChange={onChangeValue}
                    onKeyUp={e => keyEnterConfirm(e, accion)}
                />
        }
    }

    const filtrar = () => {
        const data = props.data.slice(0)

        switch (tipo) {
            case 'text':
                return data.filter(item => {
                    const itemFilter = item[key].toString().toLowerCase()

                    return itemFilter.includes(values.value.toLowerCase().trim())
                })
        
            case 'valor':
                return data.filter(item => {
                    const itemFilter = parseFloat(item[key])
                    let cond = true

                    if (values.value !== '' && values.valueEnd !== '') cond = values.value <= itemFilter && values.valueEnd >= itemFilter
                    if (values.value !== '' && values.valueEnd === '') cond = values.value <= itemFilter
                    if (values.value === '' && values.valueEnd !== '') cond = values.value >= itemFilter

                    return cond
                })

            case 'fecha':
                return data.filter(item => {
                    if (!values.value) return true
                    
                    const itemFilter = moment(item[key]).format('YYYY-MM-DD')
                    const value = moment(values.value).format('YYYY-MM-DD')

                    return itemFilter === value
                })

            case 'id':
                return data.filter(item => {
                    const itemFilter = item[key].toString()

                    return values.value === '' || itemFilter === values.value.trim()
                })

            default:
                return data.filter(item => {
                    const itemFilter = item[key].toString().toLowerCase()

                    return itemFilter.includes(values.value.toLowerCase().trim())
                })
        }
    }

    const accion = () => {
        if (props.filtrar) {
            const data = filtrar()
            props.filtrar(data)
        }

        if (props.accion) props.accion()
    }

    useEffect(() => {
        if (props.columnas !== undefined) {
            const c = props.columnas.slice(0).sort((a, b) => sort.alfAsc(a, b, 'label'))

            setColumnas(c)
        } else {
            setColumnas([])
        }

        resetForm('#form-filter')
        resetForm('#form-filter-2')
        resetForm('#form-filter-select')

        setKey('')
        setTipo('')
        setValues({
            value: '',
            valueEnd: ''
        })
    }, [JSON.stringify(props.columnas)])

    useEffect(() => {
        if (props.data && props.data.length !== 0 && key) {
            const data = filtrar()
            props.filtrar(data)
        }
    }, [props.data])

    useEffect(() => {
        if (props.setProps) {
            props.setProps({ key, tipo, values })
        }
    }, [key, tipo, values.value, values.valueEnd])

    return <div className="mx-2">
        <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text>Buscar por...</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
                id="form-filter-select"
                as="select"
                defaultValue=""
                onChange={select}
            >
                <option value=''>Seleccione una columna</option>
                { columnas.map(c => <option key={c.key} value={c.key} >{c.label}</option>) }
            </Form.Control>
            
            { tipoFiltro() }
        </InputGroup>

        <div className="text-muted">
            *Puede filtrar por alguna columna de la tabla o dejarlo vacio y traer todos los resultados.
            <br />
            *Luego de elegir un filtro vuelva a realizar la consulta o presione la tecla Enter.
        </div>
    </div>
}