import React, { useEffect, useState } from 'react'
import { Container, Col, Form, InputGroup, FormControl } from 'react-bootstrap'

import request from '../../request';
import Paginacion from './Paginacion';
import usePropsFilter from '../hooks/usePropsFilter';
import useData from '../hooks/useData';
import Filter from './Filter';
import FilterQuery from './FilterQuery';
import Notify from './Notify';
import ComboFechas from './ComboFechas';
import nameExcel from './nameExcel';
import DescargarExcel from './DescargarExcel';
import DescargarExcelLarge from './DescargaExcelLarge'
import TablaScroll from './TablaScroll';
import BotonConsultar from './BotonConsultar';
import Loader from './Loader';
import HeaderVista from './HeaderVista'


/**
 * Vista simple con modulo de fechas, filtrado, excel y boton de consulta
 * 
 * @param   {string}        titulo              Titulo de la vista
 * @param   {string}        route               Ruta de la consulta
 * @param   {string}        ipserv              Ip activa
 * @param   {Array}         columnasFilter     Columnas que se pueden filtrar (si no se envia no hay filtrado)
 * @param   {Boolean}       fch                 Habilitado el filtro por fecha, true por default
 * @param   {Boolean}       excel               Habilitada la descarga de excel, true por default
 * @param   {Array}         sucs                Array de seleccion ({ label, value }) que envia el parametro "suc" en la llamada, si se deja vacio no se mostrara el select
 * @param   {Array}         info                Array de strings con informacion para el usuario que use la vista
 * @param   {string}        nameExcel           Nombre del archivo excel
 * @param   {number}        QueId               AutQId de tabla cr_autq, undefined por default
 * 
 * Nota: Si QueId esta definido, la vista maneja las siguientes variables para las consultas en db:
 * 
 * @FCHDSD  {DATE}          fchdsd
 * @FCHHST  {DATE}          fchhst
 * @SUC     {INT}           suc
 */
export default function TemplateVistaSimple(props) {
    const [datasat, setDataSat] = useState([]);
    const [datasatPage, setDataSatPage] = useState([]);
    const [datasuc, setDataSuc] = useState('');

    const [page, setPage] = useState(1)

    const [fchdsd, setfchdsd] = useState('');
    const [fchhst, setfchhst] = useState('');

    const [normal, setNormal] = useState(false);
    const [crudo, setCrudo] = useState(false);
    const [checkedvalue, setChekedValue] = useState(false);

    const [propsFilter, setPropsFilter] = usePropsFilter()
    const [tokenExcel, setTokenExcel] = useState('')

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const rows = 50

    const [colQuery, setColQuery] = useState('')
    const [value, setValue] = useState('')

    const [filtros, setFiltros] = useData()

    const columnas = props.columnasFilter
    const col = props.colQuery

    const getdata = async () => {
        setLoading(true)
        setError(false)
        setDataSat([])
        setDataSatPage([])
        const filterQuery = colQuery ? colQuery.split(",") : []
        const filterValue = value

        let method = ''
        const url = props.ipserv + props.route + '/'
        const item = {
            ...filtros,
            fchdsd,
            fchhst,
            suc: datasuc === 'all' ? '' : datasuc
        }

        let body = {
            tokenExcel,
            propsFilter: JSON.stringify(propsFilter),
            filterQuery,
            filterValue,
            checkedvalue
        }

        if (props.QueId) {
            body = {
                ...body,
                item,
                AutQId: props.QueId
            }

            method = 'Post'
        } else {
            Object.keys(item).forEach(k => body[k] = item[k])
            method = 'Get'
        }

        request[method](url, body, r => {
            if (r.rows === 'No data') r.rows = []

            if (r.rows.length === 0) Notify({ type: 'busqueda_fail' })
            setDataSat(r.rows);
            setDataSatPage(r.rows.slice(0, rows));
            setPage(1)
            setTokenExcel(r.tokenExcel)
            setLoading(false)
            setError(false)
        }, 'json', e => {
            console.log(e)
            setLoading(false)
            setError(true)
        })
    }

    const onChangePage = (list, p) => {
        setDataSatPage(list)
        setPage(p)
    }

    const search = () => {
        let cond

        if (props.sucs && (props.fch === undefined || props.fch)) {
            cond = !fchdsd || !fchhst || (!datasuc && datasuc !== 0)
        } else if (!props.sucs && (props.fch === undefined || props.fch)) {
            cond = !fchdsd || !fchhst
        } else if (props.sucs && !props.fch) {
            cond = (!datasuc && datasuc !== 0)
        } else {
            cond = false
        }

        if (props.filtros) {
            const keysFiltros = Object.keys(filtros)

            keysFiltros.forEach(k => {
                const filtro = props.filtros.filter(f => f.key === k)[0]
                const v = filtros[k]

                console.log({ filtro, v, k })
                console.log(!v)
                console.log(filtro.required)

                if (!v && filtro.required) cond = true
            })
        }

        if (cond) {
            Notify({ type: 'completar' })
            return
        }

        getdata();
    }

    const selecsuc = e => {
        setDataSat([]);
        setDataSuc(e.target.value);
    };

    const selectcheck = (tipo) => {
        if (tipo === "normal") {
            setNormal(true)
            setCrudo(false)
        } else {
            setNormal(false)
            setCrudo(true)
        }

        setChekedValue(tipo)
    }

    useEffect(() => {
        if (props.filtros) {
            let f = {}

            props.filtros.forEach(item => f[item.key] = '')

            setFiltros(f)
        }
    }, [])

    useEffect(() => {
        if (props.sucs && props.sucs.length > 0) {
            const id = props.sucs[0].value
            setDataSuc(id)
        }
    }, [JSON.stringify(props.sucs || [])])

    return <Container fluid className="col" style={{ backgroundColor: '#FFFFFF' }}>
        <Col className="bg-white mt-4 border shadow rounded">
            <HeaderVista titulo={props.titulo} />

            <Form inline className="mt-2 ml-2">
                {(props.fch === undefined || props.fch) &&
                    <ComboFechas
                        idFormDesde={"form-dsd-" + props.idForm}
                        idFormHasta={"form-hst-" + props.idForm}
                        defaultComboMes={0}
                        soloFchDsd={props.soloFchDsd}
                        setFechDesde={setfchdsd}
                        setFechHasta={setfchhst}
                    />
                }

                {props.sucs &&
                    <Form.Control
                        className="mr-sm-2"
                        onChange={e => selecsuc(e)}
                        as="select"
                        defaultValue=""
                    >
                        {props.sucs.map(s => <option key={s.value} value={s.value}>{s.label}</option>)}
                    </Form.Control>
                }



                {props.radio && <Form >
                    <div >
                        <div >Seleccione Tipo de Reporte a Descargar   :</div>
                        <div className='d-flex'>
                            <Form.Check onChange={(e) => selectcheck("normal")}
                                className='p-2'
                                type={"checkbox"}
                                id={`default-radio`}
                                label={<h6> Normal </h6>}
                                checked={normal}

                            />
                            <Form.Check onChange={(e) => selectcheck("crudo")}
                                checked={crudo}
                                type={"checkbox"}
                                label={<h6> Crudo </h6>}
                                id={`disabled-default-radio`}

                            />
                        </div>
                    </div>
                </Form>}

                <BotonConsultar
                    disabled={loading}
                    loading={loading}
                    search={search}
                />

                {(props.excel === undefined || props.excel) &&
                    <DescargarExcel
                        disabled={datasat.length === 0 || loading}
                        route={props.route}
                        ipserv={props.ipserv}
                        tokenExcel={tokenExcel}
                        nombreExcel={nameExcel(props.nameExcel, { dsd: fchdsd, hst: fchhst })}
                    />
                }

                {
                    (props.largedata) &&
                    < DescargarExcelLarge
                        ipserv={props.ipserv}
                        disabled={datasat.length === 0 || loading}
                        dataExcel={datasat}
                        nombreExcel={nameExcel(props.nameReportLarge, { dsd: fchdsd, hst: fchhst })}
                        tipo={checkedvalue}
                    />
                }
            </Form>

            {(props.fch === undefined || props.fch) &&
                <Form.Text className="text-muted ml-2">
                    *Para Buscar la Información, debe seleccionar una Fecha Desde {!props.soloFchDsd && "y una Fecha Hasta."}
                </Form.Text>
            }

            {(props.excel === undefined || props.excel) &&
                <Form.Text className="text-muted ml-2">
                    *Para Generar el Excel, primero debe Buscar la Información.
                </Form.Text>
            }

            {(props.info || []).map(i =>
                <Form.Text className="text-muted ml-2">
                    *{i}
                </Form.Text>
            )}

            {props.columnasFilter &&
                <div className='mt-2' style={{ width: '60%' }}>
                    <Filter
                        columnas={columnas}
                        setProps={setPropsFilter}
                        accion={search}
                    />
                </div>
            }


            {props.colQuery &&
                <div className='mt-2' style={{ width: '60%' }}>
                    <FilterQuery
                        col={col}
                        acction={search}
                        setDataQuery={setColQuery}
                        setValues={setValue}
                    />
                </div>
            }

            {(props.filtros || []).sort((a, b) => a.required ? -1 : 1).map(f => <InputGroup className="my-3 half-width ml-2" key={f.label + f.key}>
                <InputGroup.Text>{f.label}</InputGroup.Text>

                <FormControl
                    type="text"
                    placeholder="Buscar"
                    name={f.key}
                    input="true"
                    onChange={setFiltros}
                />

                {f.required && <div className='ml-2 d-flex align-items-center text-negative'>*Requerido</div>}
            </InputGroup>)}

            <Loader loading={loading} error={error}>
                {datasat.length > 0 &&
                    <div className='full-width d-flex justify-content-center my-2'>
                        <Paginacion
                            page={page}
                            rows={rows}
                            data={datasat}
                            onChangePage={onChangePage}
                        />
                    </div>
                }

                <TablaScroll
                    columnas={Object.keys(datasatPage[0] || {})}
                    data={datasatPage}
                />

                {datasat.length > 0 &&
                    <div className='full-width d-flex justify-content-center my-2'>
                        <Paginacion
                            page={page}
                            rows={rows}
                            data={datasat}
                            onChangePage={onChangePage}
                        />
                    </div>
                }
            </Loader>
        </Col>
    </Container>
}
