function getParams (a, b, key = '') {
    return key
        ? [a[key], b[key]]
        : [a, b]
}

function getDate ([fa, fb]) {
    let fA = ''
    let fB = ''

    if (typeof fa === 'string') fA = fa
    if (typeof fb === 'string') fB = fb

    if (isValidDate(fa)) fA = formatFch(fa)
    if (isValidDate(fb)) fB = formatFch(fb)

    return [fA, fB]
}

function formatFch (fch) {
    const y = fch.getFullYear()
    const m = '0' + (fch.getMonth() + 1)
    const d = fch.getDay()

    return [y, m, d].join('-')
}

function isValidDate (date) {
    return Object.prototype.toString.call(date) === '[object Date]' && date.getTime()
}

export default {
    numAsc: (a, b, key = '') => {
        const [A, B] = getParams(a, b, key)

        return A - B
    },
    numDesc: (a, b, key = '') => {
        const [A, B] = getParams(a, b, key)

        return B - A
    },
    alfAsc: (a, b, key = '') => {
        const [A, B] = getParams(a, b, key)

        if (A.toLowerCase() < B.toLowerCase()) return -1

        if (A.toLowerCase() > B.toLowerCase()) return 1

        return 0
    },
    alfDesc: (a, b, key = '') => {
        const [A, B] = getParams(a, b, key)

        if (A.toLowerCase() > B.toLowerCase()) return -1

        if (A.toLowerCase() < B.toLowerCase()) return 1

        return 0
    },
    fchAsc: (a, b, key = '') => {
        const params = getParams(a, b, key)
        const [A, B] = getDate(params)

        if (A < B) return -1

        if (A > B) return 1
        
        return 0
    },
    fchDesc: (a, b, key = '') => {
        const params = getParams(a, b, key)
        const [A, B] = getDate(params)

        if (A > B) return -1

        if (A < B) return 1
        
        return 0
    }
}