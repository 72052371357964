import { useState } from "react";

export default function usePropsFilter () {
    const [props, setProps] = useState({
        key: '',
        tipo: '',
        values: {
            value: '',
            valueEnd: ''
        }
    })

    return [props, setProps]
}