import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Image, Container, Form, Button, Card, Alert } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'


export default function EchoSrv() {

    const [ip, SetUrl] = useState({
        ip: ''
    });

    var dataips = [
        {
            url: "localhost"
        },
        {
            url: "192.1.0.234"
        },
        {
            url: "192.168.0.234"
        },
        {
            url: "192.168.3.234"
        },
        {
            url: "192.20.0.234"
        }
        /* {
            url: "192.11.0.236"
        },
        {
            url: "192.0.70.234"
        } */
    ];

    var callecho = 0;


    const EchoSrv = async (url) => {

        const response = await fetch(dataips[callecho].url + ":5000/" + "api/getUsrListAct",
            {
                method: 'GET', headers: {
                    'Content-Type': 'application/json'
                }
            }

        ).then(function (response) {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response;

        }).then(async function (response) {
            const request = await response.json();
            console.log("RESPUESTA DE IP:" + dataips[callecho].url);
        }).catch(function (error) {
            console.log("SIN RESPUESTA : REINTENTAOD CON OTRO SERVER ");
            callecho++;
            EchoSrv(url);
        });
    }

    useEffect(() => {
        EchoSrv();
    }, [])

    return (
        <Container className=" my-4 text-center px-3 col d-flex justify-content-center" >
            <h2>VERIFICA IP SERVER</h2>
        </Container>
    )
}
