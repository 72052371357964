import React from "react";

export default function Loader (props) {
    return <>
        {props.loading || props.error
            ? <div className={'full-width d-flex flex-column justify-content-center align-items-center text-center ' + props.className} style={{ height: 500 }}>
                {props.loading &&
                    <>
                        <div className={'loader-' + Math.floor((Math.random() * (6 - 1 + 1)) + 1)} />
                        <span className='text-fenix fw-bold'>Su consulta esta siendo procesada, aguarde unos instantes.</span>
                    </>
                }

                {props.error &&
                    <div className='border rounded border-warning fw-bold d-flex flex-column justify-content-center' style={{ height: 100, width: 500 }}>
                        <div>Hubo un error inesperado al procesar su solicitud, intente nuevamente y si el problema persiste contacte con un administrador.</div>
                        <br/>
                        <br/>
                        <div>soporte-fenix@uniber.com.ar</div>
                    </div>
                }
            </div>
            : props.children
        }
    </>
}