import Notify from "../components/compartidos/Notify"
import sort from "../components/compartidos/sort"
import request from "../request"
import Usr from "../services/user"
import getTokenRandom from "./getTokenRandom"

const TIMEOUT = 30000

let IPSERV = ''
let TIMERID = null
let ACTIVE = false
let CANT_NUEVAS = 0
let ULT_R = null

let LISTENERS = {
    CANT_NUEVAS: [],
    NOTIS: [],
    ULT_R: []
}

let notificacionesSinVer = []

const ModelNotificacion = data => {
    const NotiParams = JSON.parse(data.NotiParams || {})
    const LinkParams = JSON.parse(data.NotiLinkParams || {})

    const keysLP = Object.keys(LinkParams)

    const LinkQuery = '?m=noti' + keysLP.map(k => `&${k}=${LinkParams[k]}`).join('')

    const defaultParams = {
        recortarBody: true,
        deleteEquivalentes: true
    }

    return {
        Id: data.NotiId,
        text: data.NotiTexto,
        Tipo: data.NotiTipo,
        Fecha: data.Fecha,
        Hora: data.Hora,
        Timestamp: data.NotiFch,
        Link: data.NotiLink,
        LinkParams,
        LinkQuery,
        keysLP,
        ...defaultParams,
        ...NotiParams
    }
}

const execListen = (e, v) => LISTENERS[e].forEach(item => item.callback(v))

const getIndexNoti = id => notificacionesSinVer.findIndex(n => n.Id === id)

const errorRequest = (resolve, e) => {
    console.log('--------------------------')
    console.log('----- ERROR: REQUEST -----')
    console.log(e)
    console.log('--------------------------')

    resolve(false)
}

const getNotis = () =>  new Promise(resolve => request.Get(IPSERV + 'getnotis/', { id: Usr.Id() }, resolve, 'json', e => errorRequest(resolve, e)))

const getAllNotis = () =>  new Promise(resolve => request.Get(IPSERV + 'getnotis/', { id: Usr.Id(), all: 1 }, resolve, 'json', e => errorRequest(resolve, e)))

const setUltRespuesta = r => {
    ULT_R = r

    execListen('ULT_R', r)
}

const setCantNuevas = (num = 0) => {
    CANT_NUEVAS = num

    execListen('CANT_NUEVAS', num)
}

const setNotis = (notis = []) => {
    notificacionesSinVer = notis
    ordenarNotis()

    execListen('NOTIS', notis)
}

const setIpServ = ip => IPSERV = ip

const insertNoti = noti => {
    const i = getIndexNoti(noti.Id)

    if (i < 0) {
        const notis = [ ...notificacionesSinVer, noti ]

        setNotis(notis)

        return true
    } else {
        return false
    }
}

const deleteNotis = ids => new Promise(resolve => {
    const notis = [ ...notificacionesSinVer ].filter(n => !ids.includes(n.Id))

    setNotis(notis)

    request.Post(IPSERV + 'deletenotis/', { ids }, r => resolve(r.success), 'json', e => errorRequest(resolve, e))
})

const deleteNoti = id => new Promise(resolve => {
    const i = getIndexNoti(id)

    if (i >= 0) {
        const noti = notificacionesSinVer[i]
        
        const ids = noti.keysLP.length > 0 && noti.deleteEquivalentes
            ? notificacionesSinVer
                .filter(n => (n.Link + n.LinkQuery).toLowerCase() === (noti.Link + noti.LinkQuery).toLowerCase())
                .map(n => n.Id)
            : [id]

        deleteNotis(ids).then(resolve)
    } else {
        resolve(false)
    }
})

const deleteNotiAll =() => new Promise(resolve => {
    const ids = notificacionesSinVer.map(n => n.Id)

    if (ids.length > 0) {
        setNotis([])
        setCantNuevas(0)

        request.Post(IPSERV + 'deletenotis/', { ids }, r => resolve(r.success), 'json', e => errorRequest(resolve, e))
    } else {
        resolve(true)
    }
})

const ordenarNotis = () => notificacionesSinVer.sort((a, b) => sort.numDesc(parseInt(a), parseInt(b)))

const bucleNotis = async (timeout = TIMEOUT) => {
    TIMERID = setTimeout(bucleNotis, timeout)

    const r = await getNotis()
    setUltRespuesta(r)
    
    console.log('------------------------------------')
    console.log('----- RESPUESTA NOTIFICACIONES -----')
    console.log(r)
    console.log('------------------------------------')

    if (r) {
        try {
            const notificaciones = r.rows.map(n => ModelNotificacion(n))
            let cantNuevas = 0

            notificaciones.forEach(n => {
                if (insertNoti(n)) cantNuevas++
            })

            ordenarNotis()

            if (cantNuevas > 0) {
                setCantNuevas(CANT_NUEVAS + cantNuevas)
                Notify({
                    text: `Tienes ${cantNuevas} Notificaciones Nuevas`,
                    classes: 'bg-fenix-gradient'
                })
            }
        } catch (error) {
            console.log('-----------------------------')
            console.log('----- ERROR: BUCLENOTIS -----')
            console.log(error)
            console.log('-----------------------------')
        }
    }
}

const getUltConsulta = () => ULT_R

const buscarNotis = () => {
    if (ACTIVE) {
        console.log('-------------------------------------------')
        console.log('----- NOTIFICACIONES YA ESTAN ACTIVAS -----')
        console.log('-------------------------------------------')
    } else {
        console.log('------------------------------------')
        console.log('----- NOTIFICACIONES ACTIVADAS -----')
        console.log('------------------------------------')

        ACTIVE = true

        bucleNotis()
    }
}

const vaciarNotis = (visto = false) => new Promise(resolve => {
    if (visto) {
        deleteNotiAll().then(resolve)
    } else {
        setNotis()
        setCantNuevas(0)

        resolve(true)
    }
})

const stopNotis = (vaciar = true) => {
    clearTimeout(TIMERID)

    TIMERID = null
    ACTIVE = false

    vaciar && vaciarNotis()
    
    console.log('---------------------------------------')
    console.log('----- NOTIFICACIONES DESACTIVADAS -----')
    console.log('---------------------------------------')
}

const isActive = () => ACTIVE

const getCantNuevas = () => CANT_NUEVAS

const getNotificaciones = () => notificacionesSinVer

const listen = (e, callback) => {
    if (LISTENERS[e]) {
        const token = getTokenRandom()

        LISTENERS[e].push({ callback, token })

        const unlisten = () => {
            const i = LISTENERS[e].findIndex(item => item.token === token)

            if (i >= 0) {
                LISTENERS[e].splice(i, 1)
                
                return true
            }

            return false
        }

        return unlisten
    }

    return null
}

const Notis = {
    // GET
    getCantNuevas,
    getNotis,
    getAllNotis,
    getUltConsulta,
    getNotificaciones,

    // SET
    setCantNuevas,
    setIpServ,
    setNotis,

    // METODOS
    buscarNotis,
    stopNotis,
    deleteNoti,
    deleteNotis,
    deleteNotiAll,
    vaciarNotis,
    ordenarNotis,
    isActive,
    insertNoti,

    // ESCUCHADOR
    listen,
    listeners: Object.keys(LISTENERS),

    // MODELO
    ModelNotificacion
}

// window.Notis = Notis

export default Notis
