import React, { useState, useRef } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import download from 'downloadjs';
import request from '../../request'
import Notify from './Notify'
import historyService from '../../services/historyService';
import getItemMenu from './getItemMenu';
import Usr from '../../services/user';
import { lastProps } from './nameExcel';
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import queueExcel from '../../services/queueExcel';

export default function DescargarExcel (props) {
    const [loading, setLoading] = useState(false)
    const cabecera = useRef(null)

    const emp = {
        GEO: 'GEOMACO',
        ELS: 'EL SOL',
        SUP: 'SUPERMAT',
        ELA: 'SUPERMAT',
        HIP: 'HIPERCAT',
        SIST: 'SISTEMAS',
        DIR: 'DASHBOARD DIRECCION'
    }

    const getFch =  () => {
        const fch = lastProps.fch

        if (!fch) return moment().format('DD/MM/YYYY')

        return typeof fch === 'string' ? moment(fch).format('DD/MM/YYYY') : `${moment(fch.dsd).format('DD/MM/YYYY')} - ${moment(fch.hst).format('DD/MM/YYYY')}`
    }

    const card = (itemExcel) => `<div class="d-flex flex-column text-center justify-content-center align-items-center alerta-excel" style="width: 500px">
                                    <div class="fw-medium">${getItemMenu() ? getItemMenu().IMenuNom : 'REPORTE'}</div>
                                    <div class="fw-medium">Hoja <span id="${itemExcel.token}-hoja">${itemExcel.hoja}</span>/${itemExcel.hojas}</div>
                                    <div class="fw-medium">Filas <span id="${itemExcel.token}-filas">${itemExcel.fila}/${itemExcel.filas}</span></div>
                                    <div class="d-flex full-width justify-content-center my-1">
                                        <div class="spinner-grow spinner-grow-sm mx-1"></div>
                                        <div class="spinner-grow spinner-grow-sm mx-1"></div>
                                        <div class="spinner-grow spinner-grow-sm mx-1"></div>
                                        <div class="spinner-grow spinner-grow-sm mx-1"></div>
                                    </div>
                                    <span class="fw-medium" id="${itemExcel.token}-label">
                                        ${itemExcel.hoja === itemExcel.hojas && itemExcel.fila === itemExcel.filas
                                            ? 'Procesando...'
                                            : 'Escribiendo...'
                                        }
                                    </span>
                                    <div class="hidden-not-hover">
                                        Por favor espere, estamos generando su informe.
                                        <br/>
                                        Puede seguir navegando por el sitio mientras espera.
                                        <br/>
                                        Si el informe contiene muchas filas puede que demore unos minutos.
                                        <br/>
                                    </div>
                                </div>`

    const esperar = (itemExcel, tokenExcel, nombre, url, paramsCong, notify, hidden) => {
        const consultar = () => request.Post(url, { tokenExcel, paramsCong }, r => {
            download(r, nombre + ".xlsx");
            hidden()
            Notify({ type: 'archivo_success', classes: 'bg-positive' })

            setLoading(false)

            if (props.success) props.success()
        }, 'blob',
        e => {
            if (e.pending) {
                console.log(document.getElementById(tokenExcel + '-hoja'), document.getElementById(tokenExcel + '-filas'), document.getElementById(tokenExcel + '-label'))
                document.getElementById(tokenExcel + '-hoja') && (document.getElementById(tokenExcel + '-hoja').innerHTML = e.hoja)
                document.getElementById(tokenExcel + '-filas') && (document.getElementById(tokenExcel + '-filas').innerHTML = `${e.fila}/${e.filas}`)
                document.getElementById(tokenExcel + '-label') && (document.getElementById(tokenExcel + '-label').innerHTML = e.hoja === e.hojas && e.fila === e.filas
                    ? 'Procesando...'
                    : 'Escribiendo...'
                )

                esperar(e, tokenExcel, nombre, url, paramsCong, notify, hidden)
            } else {
                setLoading(false)

                if (props.success) props.success()
            }
        })

        setTimeout(consultar, 5000) // espera 2 segundos
    }

    const getExcel = e => {
        e.preventDefault()

        setLoading(true)

        const url = props.ipserv + props.route + 'excel/'
        const tokenExcel = props.tokenExcel
        const nombre = props.nombreExcel || 'Reporte'
        const success = () => setLoading(false)
        const error = () => {
            setLoading(false)
            if (props.error) props.error()
        }
        const columnas = props.columnas || []
        const menu = getItemMenu()
        const menuNom = menu ? menu.IMenuNom + " - " + menu.IMenuEmp : ''
        const user = Usr
        const usuario = `${user.Apellido()}, ${user.Nombre()}`
        const fch = getFch()
        const k = getItemMenu() && getItemMenu().IMenuEmp
        const empresa = emp[k]
        const paramsCong ={
            Link: historyService.getPathname(),
            MenuId: menu && menu.IMenuId,
            MenuNom: menu && menu.IMenuNom,
            UniNeg: menu && menu.IMenuEmp,
            Usuario: `${user.Id()} - ${user.Apellido()}, ${user.Nombre()}`,
            UsrId: user.Id()
        }
        const paramsExcel = {
            titulo: menuNom,
            usuario,
            fch,
            empresa,
            crudo: !cabecera.current.checked
        }

        queueExcel.addItem({ url, tokenExcel, paramsExcel, columnas, paramsCong, nombre, success, error, menuNom })
    }

    return <div className='d-flex'>
        <Button
            disabled={loading || props.disabled}
            className={"mx-3 " + props.className}
            style={props.style}
            onClick={getExcel}
            variant={props.variant || 'success'}
        >
            {loading
                ? <Spinner animation="border" size="sm" />
                : props.botonText || <><FontAwesomeIcon icon={faFileExcel} className="mr-2" />Descargar Excel</>
            }
        </Button>

        {!loading && <div className='d-flex'>
            <input
                id="filtros-check"
                defaultChecked={true}
                ref={cabecera}
                type="checkbox"
            />
            <label className="ml-1" htmlFor="filtros-check">Excel Con Cabecera</label>
        </div>}
    </div>
}