// @mui
import PropTypes from 'prop-types';
import { Card, Typography, CardHeader, CardContent } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { Timeline, TimelineDot, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector } from '@mui/lab';
// utils
import { fDateTime } from '../../../utils/formatTime';


// ----------------------------------------------------------------------

AppOrderTimeline.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppOrderTimeline({ title, subheader, list, ...other }) {
  return (
    <Card sx={{ backgroundColor:'#C99F2E', margin:'auto'}} {...other}>
      <h2 style={{ fontFamily:'Montserrat', textAlign:'center', marginTop:'20px'}}>Premios</h2>
      <CardContent
        sx={{
          '& .MuiTimelineItem-missingOppositeContent:before': {
            display: 'none',
          },
          padding:'10px',
          margin:'auto',
          marginLeft:'65px'
        }}
      >
        <Timeline sx={{ display:'flex',justifyContent:'center', textAlign:'center', marginTop:'0px'}}>
          {list.map((item, index) => (
            <OrderItem key={item.id} item={item} isLast={index === list.length - 1} />
          ))}
        </Timeline>
      </CardContent>

      {/*<hr style={{ width:'50%',margin:'auto', color:'black', borderWidth:'0.3px',  border: '0.3px solid gray' }}></hr>

      <h2 style={{ fontFamily:'Montserrat',textAlign:'center', marginTop:'12px'}}>Usuarios con mas puntos</h2>
      <CardContent
        sx={{
          '& .MuiTimelineItem-missingOppositeContent:before': {
            display: 'none',
          },
          padding:'0px',
          margin:'auto',
          marginLeft:'65px'
        }}
      >
        <Timeline sx={{ display:'flex',justifyContent:'center', textAlign:'center'}}>
          {list2.map((item, index) => (
            <OrderItem key={item.id} item={item} isLast={index === list.length - 1} />
          ))}
        </Timeline>
      </CardContent>*/}
    </Card>
  );
}

// ----------------------------------------------------------------------

OrderItem.propTypes = {
  isLast: PropTypes.bool,
  item: PropTypes.shape({
    time: PropTypes.instanceOf(Date),
    title: PropTypes.string,
    type: PropTypes.string,
  }),
};

const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
    ],
  },
});

function OrderItem({ item, isLast }) {
  const { type, title, time } = item;
  return (
    <TimelineItem>
      <TimelineSeparator>
        {type == 'order1' ? <TimelineDot sx={{ backgroundColor:'#17B385'}}/> : null}
        {type == 'order2' ? <TimelineDot sx={{ backgroundColor:'#56A9EA'}}/> : null}
        {type == 'order3' ? <TimelineDot sx={{ backgroundColor:'#56E9E7'}}/> : null}
        {type == 'order4' ? <TimelineDot sx={{ backgroundColor:'#E68F35'}}/> : null}
        {type == 'order5' ? <TimelineDot sx={{ backgroundColor:'#ff6961'}}/> : null}
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>

      <TimelineContent>
        <Typography theme={theme} sx={{color: 'black', fontFamily:'Montserrat'}} variant="subtitle2">{title}</Typography>

        <Typography theme={theme}  variant="caption" sx={{ color: 'black', fontFamily:'Montserrat' }}>
          {fDateTime(time)}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}
