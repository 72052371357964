const getUser = () => {
    const user =  localStorage.getItem('usuario')

    return user
        ? JSON.parse(user)[0]
        : {}
}

const get = key => getUser().hasOwnProperty(key)
    ? getUser()[key]
    : null

const Usr = {
    getUser,
    Id: () => get('UsrId'),
    Conexion: () => get('Conexion'),
    FechaAlta: () => get('UsrFch'),
    Mail: () => get('UsrMail'),
    Apellido: () => get('UsrApel'),
    Nombre: () => get('UsrNom')
}

export default Usr
