import Notify from "../components/compartidos/Notify"
import history from "../history"
import Notis from "../services/notificaciones"

export default async function (method, url, body, callback, responseType, error, file) {
    const user = localStorage.usuario
        ? JSON.parse(localStorage.usuario)[0].UsrNick
        : null

    var o = {
        method,
        body: file ? body : JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + (localStorage.token || '*****') + ' User ' + (user || '')
        }
    }

    if (method === 'GET') {
        delete o.body

        const keys = Object.keys(body)

        keys.forEach((k, i) => {
            url = i === 0
                ? url + '?'
                : url + '&'

            url = `${url}${k}=${body[k]}`
        })
    }

    if (!responseType) responseType = 'json'

    if (file) delete o.headers['Content-Type']

    const notify = e => {
        console.log(e)
        Notify({ type: 'error' })
    }

    const headerIsFail = (response, key) => {
        const headerKey = key[0] + key.slice(1) + '-Fail'
        const noti = key + '_fail'

        if (response.headers.has(headerKey)) {
            console.log('---------- ' + key.toUpperCase() + ' FAIL -----------')
            console.log(response.headers.get(headerKey))
            console.log('--------------------------------')

            if (key === 'auth') {
                Notis.stopNotis()
                localStorage.clear();
                history.push('/Login')
            }

            if (key === 'per') history.push('/')

            if (key === 'exc') {
                Notify('Hubo un error al procesar la informacion, vuelva a consultar e intente nuevamente')
                if (error) error()
                return true
            }

            Notify({ type: noti })

            return true
        } else {
            return false
        }
    }

    const headerPending = (response, key) => {
        const headerKey = key[0] + key.slice(1) + '-Pending'
        // const noti = key + '_fail'

        if (response.headers.has(headerKey)) {
            console.log('---------- ' + key.toUpperCase() + ' FAIL -----------')
            console.log(response.headers.get(headerKey))
            console.log('--------------------------------')

            if (key === 'exc') {
                response.json().then(r => error(r))
                return true
            }

            return true
        } else {
            return false
        }
    }

    const Error = e => error === null ? notify(e) : error(e)

    await fetch(url, o).then(async function (r) {
        if (!r.ok) {
            throw Error(r.statusText)
        } else {
            if (!headerIsFail(r, 'auth') && !headerIsFail(r, 'per') && !headerIsFail(r, 'exc') && !headerPending(r, 'exc'))
                await r[responseType]()
                    .then(callback)
                    .catch(Error)
        }
    }).catch(Error);
}