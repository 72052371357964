import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { Modal, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/esm/ModalHeader"
import request from "../../../request"
import Loader from "../../compartidos/Loader"
import Notify from "../../compartidos/Notify"

export default function SelectIconos (props) {
    const [icons, setIcons] = useState([])
    const [loading, setLoading] = useState(false)

    const getIcons = async () => {
        if (icons.length > 0) return

        setLoading(true)

        const module = await import('@fortawesome/free-solid-svg-icons')

        request.Get(props.ipserv + 'geticonsmenu/', {}, r => {
            setLoading(false)

            if (r.success) {
                const array = r.data.map(d => {
                    return {
                        label: d.DmnNom,
                        icon: module[d.DmnVal],
                        value: d.DmnVal
                    }
                })

                setIcons(array)
            } else {
                Notify({ type: 'fail' })
            }
        })
    }

    useEffect(() => {
        if (props.show) getIcons()
    }, [props.show])

    return <Modal show={props.show} onHide={() => props.setShow(false)} centered>
    <ModalHeader>
        <h5>Elige un icono para el Acceso Directo</h5>
    </ModalHeader>

    <Modal.Body className="text-no-desborde" id="modal-data-log">
        <Loader loading={loading}>
            <div className="row justify-content-beetwen align-items-center">
                {icons.map(i => <div
                    key={i.label}
                    className="d-flex col-4 flex-column justify-content-center card-scale fw-bold align-items-center py-3 text-center cursor-pointer"
                    onClick={e => props.selectIcon(i.value)}
                >
                    <FontAwesomeIcon size="3x" icon={i.icon} color="blue" />

                    {i.label}
                </div>)}
            </div>
        </Loader>
    </Modal.Body>
</Modal>
}