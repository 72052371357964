import React, { useState, useEffect } from 'react';
import {
    Container,
    Col,
    Row,
    Form,
    Table
} from 'react-bootstrap';

import request from '../../request';
import Notify from '../compartidos/Notify';
import Loader from '../compartidos/Loader';
import EditarParam from './EditParametrosPrestige'
import HeaderVista from '../compartidos/HeaderVista';


export default function ParametrosEditables(props) {

    const [datasat, setDataSat] = useState([]);
    const [loading, setLoading] = useState(false);


    const getParam = async () => {
        request.Get(props.ipserv + 'getparametroseditables/', {}, r => {
            if (r.success) {
                setDataSat(r.rows);
            } else {
                Notify('No Se encontraron Datos')
            }
        })
    }


    useEffect(() => {
        if (props.ipserv.length > 0) {
            getParam();
        }
    }, [props.ipserv])

    useEffect(() => {

    }, [datasat])

    return (

        <Container fluid className="col" style={{ backgroundColor: '#FFFFFF' }}>

            <Col className="bg-white mt-4 border shadow rounded ">
            <HeaderVista titulo={'Panel de Administracion de Parametros'} />


                <Form className="d-flex justify-content-center">

                    <Loader loading={loading} />

                    {datasat.length > 0 ?
                        <Table className='mt-3' striped bordered hover>

                            <thead  >
                                <tr>
                                    <th>Parámetro</th>
                                    <th>Valor</th>
                                    <th>Acción</th>
                                </tr>
                            </thead>


                            <tbody >
                                {datasat.length > 0 ? datasat.map(rows =>
                                    <tr>
                                        <td > {rows.DmnNom} </td>
                                        <td > {rows.DmnVal} </td>

                                        <td > <EditarParam props={{ipserv:props.ipserv , formDmnVal : rows.DmnVal  , formDmnNom:rows.DmnNom , formDmnId: rows.DmnId , getParam:getParam , formtabla:rows.Tabla }} /> </td>
                                    </tr>
                                ) : ""}

                            </tbody>
                        </Table>

                        : ""}
                </Form>
            </Col>
        </Container >

    )
}