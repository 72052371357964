import { faArrowUp, faCheck, faCircle, faClock, faDownload, faFileExcel, faStopCircle, faTimes, faTimesCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import queueExcel from "../../../services/queueExcel";

export default function Queue (props) {
    const [queue, setQueue] = useState(queueExcel.queue)
    const [open, setOpen] = useState(false)
    const [show, setShow] = useState(false)

    const [cant, setCant] = useState({
        pending: 0,
        completed: 0,
        fail: 0
    })

    const listenQueue = q => {
        const cantItems = f => q.filter(item => item[f]).length

        setQueue(q)
        setCant({
            pending: cantItems('pending'),
            completed: cantItems('completed'),
            fail: cantItems('fail')
        })

        setShow(q.length > 0)
    }

    const iconItem = q => {
        if (q.processing) return <FontAwesomeIcon size="lg" icon={faDownload} className="mr-2" />
        if (q.pending) return <FontAwesomeIcon size="lg" icon={faClock} className="mr-2 text-info" />
        if (q.fail) return <FontAwesomeIcon size="lg" icon={faTimesCircle} className="mr-2 text-negative" />
        if (q.completed) return <FontAwesomeIcon size="lg" icon={faCheck} className="mr-2 text-positive" />
    }

    useEffect(() => queueExcel.setListen(listenQueue), [])

    return show &&
        <div className="cola-parent rounded text-white bg-fenix fixed-bottom ml-auto mr-4 mb-3 p-3" style={{ zIndex: 1, width: 400 }}>
            <div className="d-flex align-items-center">
                {cant.pending !== 0
                    ? <>
                        <Spinner className="mr-2" animation="border" size="sm" />
                        <span className="fw-bold">{cant.pending} archivos en cola</span>
                    </>
                    : <>
                        <FontAwesomeIcon icon={faCheck} className="mr-2" />

                        <span className="fw-bold">{cant.completed} archivos de {queue.length} completados</span>
                    </>
                }

                <div className="ml-auto fw-medium d-flex flex-column">
                    <div>Procesados: {cant.completed + cant.fail}/{queue.length}</div>
                    <div>Fallos: {cant.fail}</div>
                </div>
            </div>

            <div className="linea-horizontal my-3" />

            <div className="cola-excel">
                {queue.map(q => <div key={q.tokenQueue} className="d-flex align-items-center mt-1 py-3 fs-13">
                    <div className="d-flex align-items-center">
                        {iconItem(q)}

                        <div>{q.menuNom}</div>
                    </div>

                    <div className="ml-2">
                        {q.processing && queueExcel.porcent(q)}
                    </div>

                    {!q.processing && <div className="ml-auto d-flex align-items-center justify-content-center">
                        {q.pending && <FontAwesomeIcon
                            onClick={() => queueExcel.elevar(q.tokenQueue)}
                            icon={faArrowUp}
                            size="lg"
                            className="mx-2 cursor-pointer text-secondary"
                        />}

                        {(q.completed || q.fail) && <FontAwesomeIcon
                            onClick={() => queueExcel.activateItem(q.tokenQueue)}
                            size="lg"
                            icon={faDownload}
                            className="mx-2 cursor-pointer"
                        />}

                        <FontAwesomeIcon
                            onClick={() => queueExcel.deleteItem(q.tokenQueue)}
                            icon={faTrash}
                            size="lg"
                            className="mx-2 cursor-pointer text-dark"
                        />
                    </div>}
                </div>)}
            </div>
        </div>
}