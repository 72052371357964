import React, { useState, useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import Notify from '../compartidos/Notify';
import Loader from '../compartidos/Loader';
import request from '../../request';

export default function EditParametrosPrestige({ props }) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [params, setParams] = useState({
        formDmnVal: props.formDmnVal,
        formDmnNom: props.formDmnNom,
        formDmnId: props.formDmnId , 
        formtabla:props.formtabla

    });

    const {
        formDmnVal,
        formDmnNom,
        formDmnId ,
        formtabla
    } = params;


    const saveparam = () => {
        request.Post(props.ipserv + 'saveparameters/', { DmnVal: formDmnVal, DmnId: formDmnId, DmnNom: formDmnNom , Tabla:formtabla }, r => {
            if (r.success) {
                Notify("Parametro Editado");
                props.getParam()
                setShow(false)
            } else {
                Notify("Se Produjo un error guardando los cambios");
                cleanparams();
            }
        })
    }

    const cleanparams = () => {
        setParams({
            formDmnVal: '',
            formDmnNom: '',
            formDmnId: '' , 
            formtabla:''

        });
    }

    const onSubmitEditegresos = e => {
        e.preventDefault();
        saveparam(params);
    }

    const onChangeparams = e => {
        setParams({
            ...params,
            [e.target.name]: e.target.value
        });
    };

   
    useEffect(() => {

    }, [show]);



    return (
        <>
            <Button variant="primary" onClick={handleShow} style={{ background: 'linear-gradient(250deg, rgb(151,120,209) 20%, rgb(132, 75, 129))', }}>
                <FontAwesomeIcon icon={faEdit} className="mr-2" />
            </Button>


            <Modal show={show} onHide={handleClose} centered>
                <Form onSubmit={onSubmitEditegresos}>
                    <Modal.Header closeButton>
                        <Modal.Title> Editar Parametro {formDmnNom}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Form.Group className="mb-3" controlId="formDmnVal">
                            <Form.Label>Valor</Form.Label>
                            <Form.Control onChange={onChangeparams} name="formDmnVal" value={formDmnVal} required type="text" placeholder="Ingrese Valor" />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                        <Button variant="primary" type="submit" style={{ background: 'linear-gradient(250deg, rgb(151,120,209) 20%, rgb(132, 75, 129))', }}>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </>
    );
}